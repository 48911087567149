import {
    GET_PRESSRELEASES_START,
    GET_PRESSRELEASES_SUCCESS,
    GET_PRESSRELEASES_ERROR,
    UPDATE_PRESS_RELEASE_START,
    UPDATE_PRESS_RELEASE_SUCCESS,
    UPDATE_PRESS_RELEASE_ERROR,
    ADD_PRESS_RELEASE_START,
    ADD_PRESS_RELEASE_SUCCESS,
    ADD_PRESS_RELEASE_ERROR,
    GET_PRESS_RELEASE_FILE_START,
    GET_PRESS_RELEASE_FILE_SUCCESS,
    GET_PRESS_RELEASE_FILE_ERROR,
    DELETE_PRESS_RELEASE_START,
    DELETE_PRESS_RELEASE_SUCCESS,
    DELETE_PRESS_RELEASE_ERROR,
    SET_PR_CONFIRMED_START,
    SET_PR_CONFIRMED_SUCCESS,
    SET_PR_CONFIRMED_ERROR,
    RESET_PRESS_RELEASE_ERROR,
    UPLOAD_PR_FILE_START,
    UPLOAD_PR_FILE_SUCCESS,
    UPLOAD_PR_FILE_ERROR,
} from "../actions/pressReleasesActions";

export default function pressReleasesReducer(
    state = {
        isFetching: false,
        error: null,
        pageNumber: 1,
        totalCount: 0,
        pressReleases: [],
        html: null,
        newID: null
    }, action ) {
    switch( action.type ) {
        case GET_PRESSRELEASES_START:
        case UPDATE_PRESS_RELEASE_START:
        case DELETE_PRESS_RELEASE_START:
        case SET_PR_CONFIRMED_START:
        case UPLOAD_PR_FILE_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null
            } )
        case GET_PRESSRELEASES_ERROR:
        case UPDATE_PRESS_RELEASE_ERROR:
        case ADD_PRESS_RELEASE_ERROR:
        case GET_PRESS_RELEASE_FILE_ERROR:
        case DELETE_PRESS_RELEASE_ERROR:
        case SET_PR_CONFIRMED_ERROR:
        case UPLOAD_PR_FILE_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case UPDATE_PRESS_RELEASE_SUCCESS:
        case DELETE_PRESS_RELEASE_SUCCESS:
        case UPLOAD_PR_FILE_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
            } )
        case GET_PRESSRELEASES_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                pageNumber: action.pageNumber,
                totalCount: action.totalCount,
                pressReleases: action.pressReleases
            } )
        case ADD_PRESS_RELEASE_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
                newID: null
            } )
        case ADD_PRESS_RELEASE_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                newID: action.newID
            } )
        case GET_PRESS_RELEASE_FILE_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
                html: null
            } )
        case GET_PRESS_RELEASE_FILE_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                html: action.html
            } )
        case SET_PR_CONFIRMED_SUCCESS:
            var pressReleases = state.pressReleases.map( pr => {
                if( pr.id === action.id ) {
                    return {
                        ...pr,
                        is_confirmed: action.checked
                    }
                } else return pr;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                pressReleases
            } )
        case RESET_PRESS_RELEASE_ERROR:
            return Object.assign( {}, state, {
                error: null
            } )
        default:
            return state;
    }
}
