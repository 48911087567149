import AuthService from "../components/Auth/AuthService";
const Auth = new AuthService();

export class RegistrationDataAccess extends AuthService {
    getEmailExists = ( email ) => {
        return Auth.get( "/api/Registration/EmailExists", { email: email } );
    }

    registerEmail = ( email, password ) => {
        return Auth.post( '/api/Registration/Register', { email: email, password: password } );
    }

    confirmRegistration = ( guid ) => {
        return Auth.post( "/api/Registration/Confirm", { guid: guid } );
    }
}    
