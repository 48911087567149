import React, { Component } from "react";
import AuthService from "./AuthService";

export default function withAuth( AuthComponent ) {

    const Auth = new AuthService();

    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null
            }
        }
        componentWillMount() {
            if( !Auth.loggedIn() ) {
                //alert(this.props.history.location.pathname);
                this.props.history.push( this.props.history.location.pathname );
                this.props.history.replace( "/login" )
            } else {
                try {
                    const profile = Auth.getProfile();
                    this.setState( {
                        user: profile
                    } );
                } catch( err ) {
                    Auth.logout();
                    this.props.history.replace( "/login" );
                }
            }
        }

        render() {
            if( !Auth.loggedIn() ) {
                //alert(this.props.history.location.pathname);
                this.props.history.push( this.props.history.location.pathname );
                this.props.history.replace( "/login" );
                return null;
            }

            if( this.state.user ) {
                return (
                    <AuthComponent history={this.props.history} user={this.state.user} {...this.props} />
                )
            }
            else {
                return null
            }
        }
    };
}
