import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers/index'

import thunkMiddleware from 'redux-thunk'
//import { createLogger } from 'redux-logger'

//const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware//, // lets us dispatch() functions
        //loggerMiddleware // neat middleware that logs actions
    ) )

ReactDOM.render( (
    <Provider store={store}>
        <App />
    </Provider>
), document.getElementById( 'root' ) );
