import {
    FETCH_SUMMARIES_START,
    FETCH_SUMMARIES_SUCCESS,
    FETCH_SUMMARIES_ERROR
} from "../actions/summariesActions";

export default function summaryReducer(
    state = {
        isFetching: false,
        error: null,
        pageNumber: 1,
        totalCount: 0,
        summaries: []
    }, action ) {
    switch( action.type ) {
        case FETCH_SUMMARIES_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case FETCH_SUMMARIES_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                pageNumber: action.pageNumber,
                totalCount: action.totalCount,
                summaries: action.summaries
            } )
        case FETCH_SUMMARIES_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
