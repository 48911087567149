import React from 'react';
import PropTypes from "prop-types";

function PageHeader( props ) {
    return <div className="page-header"><h3>{props.name}</h3></div>;
}

PageHeader.propTypes = {
    name: PropTypes.string.isRequired
};

export default PageHeader;