import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSubscription, setSubscription } from '../../../actions/subscriptionsActions';

import { Form, Alert } from 'react-bootstrap';
import withAuth from '../../Auth/withAuth';
import Spinner from '../../Spinner';

class SubscriptionCheckbox extends React.Component {
    constructor() {
        super();
        this.state = { isSubscribed: false };
    }

    setChecked = ( checked ) => {
        this.setState( { isSubscribed: checked } );

        this.props.setSubscription_( checked );
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( prevProps === this.props )
            return;

        const { isSubscribed } = this.props;
        this.setState( { isSubscribed } );

        if( prevProps.cbEnabled !== this.props.cbEnabled ) {
            if( !this.props.cbEnabled )
                this.setChecked( false );
        }
    }

    componentDidMount() {
        this.props.getSubscription_();
    }

    onCheckSubscribe = ( event ) => {
        this.setChecked( event.target.checked );
    }

    render() {
        const { isFetching, error } = this.props;

        return (
            <React.Fragment>
                {isFetching && <Spinner />}
                {!isFetching && error && <Alert variant="danger">Ошибка загрузки/сохранения признака подписки: {error.message}.</Alert>}
                {!isFetching && !error && <Form.Check
                    type="checkbox"
                    className={this.props.cbEnabled ? '' : 'disabledCheckBox'}
                    disabled={!this.props.cbEnabled}
                    checked={this.state.isSubscribed}
                    onChange={this.onCheckSubscribe}
                    label="Подписка на рассылку" />
                }
            </React.Fragment>
        );
    }
}

SubscriptionCheckbox.propTypes = {
    cbEnabled: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
    getSubscription_: PropTypes.func.isRequired,
    setSubscription_: PropTypes.func.isRequired
};


function mapStateToProps( state ) {
    const { subscriptionsReducer } = state;
    const { isFetching, error, isSubscribed } = subscriptionsReducer || { isFetching: true, error: null, isSubscribed: false }

    return {
        isFetching,
        error,
        isSubscribed
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        getSubscription_: () => {
            dispatch( getSubscription() );
        },
        setSubscription_: isSubscribed => {
            dispatch( setSubscription( isSubscribed ) );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( SubscriptionCheckbox ) );