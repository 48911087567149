import React from "react";
import { Table, Alert, Form, Button } from "react-bootstrap";
//import './EventsGrid.css';
import Dialog from "react-bootstrap-dialog";
import PropTypes from "prop-types";
import ModalViewFile from "./ModalViewFile";
import ModalEditPressRelease from "./ModalEditPressRelease";
import AuthService from "../Auth/AuthService";
import Octicon, { KebabHorizontal, X, FileMedia } from "@githubprimer/octicons-react";

class PressReleasesGrid extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            modalViewImageFilename: "",
            ifShowModalViewImage: false,
            ifShowModalEdit: false,
        };

        this.Auth = new AuthService();
    }

    onSavePressRelease = pr => {
        this.setState( { ifShowModalEdit: false } );

        if( pr.id !== -1 ) this.props.onUpdatePressRelease( pr );
        else this.props.onAddPressRelease( pr );
    };

    editPressRelease = pr => {
        this.setState( { currentPressRelease: pr, ifShowModalEdit: true } );
    };

    removePressRelease = id => {
        this.dialog.show( {
            title: "Подтверждение",
            body: "Вы действительно хотите удалить пресс-релиз?",
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction( () => {
                    this.props.onRemovePressRelease( id );
                } ),
            ],
            size: "sm"
        } );
    };

    addPressRelease = () => {
        var pr = {
            id: -1,
            text: "",
            filename: "",
            can_edit: true,
            date_modified: new Date().toString(),
        };

        this.setState( { currentPressRelease: pr, ifShowModalEdit: true } );
    };

    setConfirmed( id, event ) {
        /*console.log('ID ', id);
        console.log('Event', event.target.checked);*/

        this.props.setConfirmed( id, event.target.checked );
    }

    render() {
        let pressReleases = this.props.pressReleases;

        if( !pressReleases )
            return (
                <React.Fragment>
                    <table className="width100">
                        <tr>
                            <td>
                                {this.Auth.canEdit() && (
                                    <div className="floatRight margin14">
                                        <Button variant="primary" onClick={this.addPressRelease}>Добавить...</Button>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr className="width100">
                            <td className="width100">
                                <Alert variant="info"><strong>Сервер не вернул ни одного пресс-релиза!</strong></Alert>
                            </td>
                        </tr>
                    </table>
                    <ModalEditPressRelease
                        show={this.state.ifShowModalEdit}
                        onClose={() =>
                            this.setState( { ifShowModalEdit: false } )
                        }
                        pressRelease={this.state.currentPressRelease}
                        onSubmit={this.onSavePressRelease}
                    />
                </React.Fragment>
            );

        let rows = pressReleases.map( pr => (
            <tr key={pr.id}>
                {this.Auth.canEdit() && (
                    <td align="left" style={{ whiteSpace: "nowrap" }}>
                        {pr.id}
                    </td>
                )}
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {pr.date_modified}
                </td>
                <td align="left">
                    {pr.text}
                </td>
                <td align="center">
                    {pr.filename && (
                        <Button
                            variant="light"
                            size="sm"
                            onClick={() =>
                                this.setState( {
                                    modalViewImageFilename: pr.filename,
                                    ifShowModalViewImage: true,
                                } )
                            }
                        >
                            <Octicon icon={FileMedia} ariaLabel="Файл" />
                        </Button>
                    )}
                </td>
                {this.Auth.canEdit() && (
                    <React.Fragment>
                        <td align="center">
                            <Form.Check
                                type="checkbox"
                                checked={pr.is_confirmed}
                                disabled={!pr.can_confirm}
                                className={
                                    pr.can_confirm ? "" : "disabledCheckBox"
                                }
                                onChange={e => this.setConfirmed( pr.id, e )}
                            />
                        </td>
                        <td align="center">
                            {pr.can_edit}
                            {pr.can_edit && (
                                <React.Fragment>
                                    <Button variant="outline-secondary" size="sm" onClick={() => this.editPressRelease( pr )}>
                                        <Octicon icon={KebabHorizontal} ariaLabel="Редактировать" />
                                    </Button>
                                    &nbsp;
                                    <Button variant="outline-secondary" size="sm" onClick={() => this.removePressRelease( pr.id )}>
                                        <Octicon icon={X} ariaLabel="Удалить" />
                                    </Button>
                                </React.Fragment>
                            )}
                        </td>
                    </React.Fragment>
                )}
            </tr>
        ) );

        return (
            <React.Fragment>
                {this.Auth.canEdit() && (
                    <div className="floatRight margin14">
                        <Button variant="primary" onClick={this.addPressRelease}>
                            Добавить...
                        </Button>
                    </div>
                )}

                <div>
                    <Table striped bordered condensed="true" hover>
                        <thead>
                            <tr>
                                {this.Auth.canEdit() && <th>#</th>}
                                <th>Дата</th>
                                <th>Текст</th>
                                <th>Файл</th>
                                {this.Auth.canEdit() && (
                                    <React.Fragment>
                                        <th>Одобрено</th>
                                        <th>Действия</th>
                                    </React.Fragment>
                                )}
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    <ModalEditPressRelease
                        show={this.state.ifShowModalEdit}
                        onClose={() =>
                            this.setState( { ifShowModalEdit: false } )
                        }
                        pressRelease={this.state.currentPressRelease}
                        onSubmit={this.onSavePressRelease}
                    />
                    <Dialog
                        ref={el => {
                            this.dialog = el;
                        }}
                    />
                    <ModalViewFile
                        filename={this.state.modalViewImageFilename}
                        show={this.state.ifShowModalViewImage}
                        onClose={() =>
                            this.setState( { ifShowModalViewImage: false } )
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

PressReleasesGrid.propTypes = {
    onRemovePressRelease: PropTypes.func.isRequired,
    onUpdatePressRelease: PropTypes.func.isRequired,
    onAddPressRelease: PropTypes.func.isRequired,
    setConfirmed: PropTypes.func.isRequired,
};

export default PressReleasesGrid;
