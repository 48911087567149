import React from 'react';
import { Pagination, Container, Row, Col } from 'react-bootstrap';

class Pager extends React.Component {
    onPageChange = ( i, event ) => {
        this.props.onPageChange( i );
    }

    goPrevPage = () => {
        this.onPageChange( this.props.pageNumber - 1 );
    }

    goNextPage = () => {
        this.onPageChange( this.props.pageNumber + 1 );
    }

    render() {
        let pagesCount = Math.ceil( this.props.recCount / this.props.itemsPerPage );

        const startPage = this.props.pageNumber > this.props.margin ? this.props.pageNumber - this.props.margin : 1;
        const endPage = this.props.pageNumber + this.props.margin > pagesCount ? pagesCount : this.props.pageNumber + this.props.margin;

        const items = [];

        for( let i = startPage; i <= endPage; i++ ) // use let here to avoid closure
            items.push(
                <Pagination.Item
                    key={i}
                    active={this.props.pageNumber === i ? true : false}
                    onClick={( e ) => this.onPageChange( i, e )}>
                    {i}
                </Pagination.Item>
            );

        const firstPage = this.props.pageNumber > 1
            ? <Pagination.First onClick={( e ) => this.onPageChange( 1, e )} title="Первая страница" />
            : null;

        const firstPageNumber = this.props.pageNumber - this.props.margin > 1
            ? <Pagination.Item
                key={1}
                onClick={( e ) => this.onPageChange( 1, e )}>
                {1}
            </Pagination.Item>
            : null;

        const lastPage = pagesCount - this.props.pageNumber > 0
            ? <Pagination.Last onClick={( e ) => this.onPageChange( pagesCount, e )} title="Последняя страница" />
            : null;

        const lastPageNumber = pagesCount - this.props.pageNumber - this.props.margin > 0
            ? <Pagination.Item
                key={pagesCount}
                onClick={( e ) => this.onPageChange( pagesCount, e )}>
                {pagesCount}
            </Pagination.Item>
            : null;

        const prevPage = this.props.pageNumber === 1
            ? null
            : <Pagination.Prev onClick={this.goPrevPage} title="Предыдущая страница" />;

        const nextPage = this.props.pageNumber === pagesCount || pagesCount === 0
            ? null
            : <Pagination.Next onClick={this.goNextPage} title="Следующая страница" />;

        const firstEllipsis = firstPageNumber != null && this.props.pageNumber - this.props.margin > 2
            ? <Pagination.Ellipsis disabled />
            : null;

        const lastEllipsis = lastPageNumber != null && pagesCount - this.props.pageNumber + this.props.margin > 2
            ? <Pagination.Ellipsis disabled />
            : null;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col md="auto" className="align-self-baseline">Всего:&nbsp;<b>{this.props.recCount}</b>&nbsp;</Col>
                        <Col className="align-self-baseline">
                            <Pagination>
                                {firstPage}
                                {prevPage}
                                {firstPageNumber}
                                {firstEllipsis}
                                {items}
                                {lastEllipsis}
                                {lastPageNumber}
                                {nextPage}
                                {lastPage}
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Pager;