import React from 'react';
import { Form } from 'react-bootstrap';

export const FieldCheckbox = props => {
    return (
        <React.Fragment>
            {/*<input
                {...props.input}
                type="checkbox"
                checked={props.input.value}
            />*/}
            <Form.Check
                type="checkbox"
                {...props.input}
                checked={props.input.value}
            />
        </React.Fragment>
    );
}
export default FieldCheckbox;