import UserDataAccess from "../DataAccess/UserDataAccess";
const DataAccess = new UserDataAccess();

//#region getProfile
export const GET_PROFILE_START = "GET_PROFILE_START";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

function getProfileStart() {
    return {
        type: GET_PROFILE_START
    }
}

function getProfileSuccess( profile ) {
    return {
        type: GET_PROFILE_SUCCESS,
        profile: profile
    }
}

function getProfileError( error ) {
    return {
        type: GET_PROFILE_ERROR,
        error: error
    }
}

export function getProfile() {
    return dispatch => {
        dispatch( getProfileStart() );

        return DataAccess.getUser()
            .then( response => response.json() )
            .then( json => dispatch( getProfileSuccess( json ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки профиля";
                dispatch( getProfileError( error ) );
            } );
    }
}
//#endregion
