import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
//import { Nav, NavLink } from "react-router-dom";
//import * as ReactBootstrap from 'react-bootstrap';
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import logo from '../logo.gif';
import AuthService from "./Auth/AuthService";


// The Header creates links that can be used to navigate
// between routes.

//let Navbar = ReactBootstrap.Navbar;
/*let Nav = ReactBootstrap.Nav;
let NavItem = ReactBootstrap.NavItem;
let MenuItem = ReactBootstrap.MenuItem;*/
//let NavbarHeader = ReactBootstrap.NavbarHeader;
//let NavDropdown = ReactBootstrap.NavDropdown;
/*let LinkContainer = ReactRouterBootStrap.LinkContainer;
let IndexContainer = ReactRouterBootStrap.IndexLinkContainer;*/

/*<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
        </Nav>
        <Nav>
            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
                Dank memes
  </Nav.Link>
        </Nav>
    </Navbar.Collapse>
</Navbar>

    < Navbar className="bg-light justify-content-between" >
        <Form inline>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </InputGroup>
        </Form>
        <Form inline>
            <FormControl type="text" placeholder="Search" className=" mr-sm-2" />
            <Button type="submit">Submit</Button>
        </Form>
    </Navbar >

*/



class TopMenu extends Component {
    constructor( props ) {
        super( props );

        this.Auth = new AuthService();
    }

    redirectToLogin = () => {
        if( this.props.history.location.pathname !== "/login" )
            this.props.history.push( this.props.history.location.pathname );

        this.props.history.replace( "/login" );
    }

    doLogout = () => {
        this.Auth.logout();
        this.props.history.replace( '/' );
    }

    onSelect = ( selectedKey ) => {
        /*alert( this.props.location.pathname );
        alert( `selected ${ selectedKey }` );*/
    }

    render() {
        if( this.Auth.loggedIn() && this.props.location.pathname === "/login" )
            return <Redirect to='/' />;

        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    {' BizLocation'}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" variant="pills" activeKey={this.props.location.pathname} onSelect={this.onSelect}>
                        <Nav.Link href="/">&nbsp;Главная&nbsp;</Nav.Link>
                        <Nav.Link href="/PressReleases">Пресс-релизы</Nav.Link>
                        {/*<Nav.Link href="/Pricelist">Цены</Nav.Link>*/}
                        <Nav.Link href="/About">Помощь</Nav.Link>
                        {this.Auth.canEdit() && (
                            <NavDropdown title="Управление" id="collasible-nav-dropdown" bg="dark" variant="dark">
                                <NavDropdown.Item href="/Events">События</NavDropdown.Item>
                                {/*<NavDropdown.Item href="/Ads">Реклама</NavDropdown.Item>*/}
                                {this.Auth.isAdmin() && (
                                    <React.Fragment>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="/ManageUserIntention">Намерения</NavDropdown.Item>
                                    </React.Fragment>
                                )}
                            </NavDropdown>
                        )}
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        {!this.Auth.loggedIn() && (
                            <React.Fragment>
                                <Nav variant="pills" activeKey={this.props.location.pathname} onSelect={this.onSelect}>
                                    <Nav.Link href="/register">Регистрация</Nav.Link>
                                </Nav>
                                &nbsp;
                                <Form inline>
                                    <Button onClick={this.redirectToLogin} variant="outline-primary">Вход</Button>
                                </Form>
                            </React.Fragment> )}
                        {this.Auth.loggedIn() && (
                            <React.Fragment>
                                <NavDropdown title={this.Auth.getUserEmail()} id="collasible-nav-dropdown" bg="dark" variant="dark">
                                    <NavDropdown.Item href="/Cabinet_Profile">Профиль</NavDropdown.Item>
                                    <NavDropdown.Item href="/Cabinet_Isic">Отрасль</NavDropdown.Item>
                                    <NavDropdown.Item href="/Cabinet_UserIntention">Намерения</NavDropdown.Item>
                                    <NavDropdown.Item href="/Cabinet_Updates">Обновления</NavDropdown.Item>
                                </NavDropdown>
                                <Form inline>
                                    <Button onClick={this.doLogout} variant="outline-primary">Выход</Button>
                                </Form>
                            </React.Fragment>
                        )}

                    </Navbar.Collapse>
                </Navbar.Collapse>
            </Navbar >
        );
    }
}

export default withRouter( TopMenu );
