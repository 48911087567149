import { RegistrationDataAccess } from "../DataAccess/RegistrationDataAccess";
const DataAccess = new RegistrationDataAccess();

//#region getEmailexists
export const GET_EMAIL_EXISTS_START = "GET_EMAIL_EXISTS_START";
export const GET_EMAIL_EXISTS_SUCCESS = "GET_EMAIL_EXISTS_SUCCESS";
export const GET_EMAIL_EXISTS_ERROR = "GET_EMAIL_EXISTS_ERROR";

function getEmailexistsStart() {
    return {
        type: GET_EMAIL_EXISTS_START
    }
}

function getEmailexistsSuccess( emailExists ) {
    return {
        type: GET_EMAIL_EXISTS_SUCCESS,
        emailExists: emailExists
    }
}

function getEmailexistsError( error ) {
    return {
        type: GET_EMAIL_EXISTS_ERROR,
        error: error
    }
}

export function getEmailexists( email ) {
    return dispatch => {
        dispatch( getEmailexistsStart() );

        return DataAccess.getEmailExists( email )
            .then( response => response.json() )
            .then( json => dispatch( getEmailexistsSuccess( json.emailExists ) ) )
            .catch( error => {
                error.name = "Ошибка проверки существования e-mail";
                dispatch( getEmailexistsError( error ) );
            } );
    }
}
//#endregion

//#region registerEmail
export const REGISTER_EMAIL_START = "REGISTER_EMAIL_START";
export const REGISTER_EMAIL_SUCCESS = "REGISTER_EMAIL_SUCCESS";
export const REGISTER_EMAIL_ERROR = "REGISTER_EMAIL_ERROR";

function registerEmailStart() {
    return {
        type: REGISTER_EMAIL_START
    }
}

function registerEmailSuccess() {
    return {
        type: REGISTER_EMAIL_SUCCESS
    }
}

function registerEmailError( error ) {
    return {
        type: REGISTER_EMAIL_ERROR,
        error: error
    }
}

export function registerEmail( email, password ) {
    return dispatch => {
        dispatch( registerEmailStart() );

        return DataAccess.registerEmail( email, password )
            .then( () => {
                dispatch( registerEmailSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка регистрации e-mail";
                dispatch( registerEmailError( error ) );
            } );
    }
}
//#endregion

//#region confirmRegistration
export const CONFIRM_REGISTRATION_START = "CONFIRM_REGISTRATION_START";
export const CONFIRM_REGISTRATION_SUCCESS = "CONFIRM_REGISTRATION_SUCCESS";
export const CONFIRM_REGISTRATION_ERROR = "CONFIRM_REGISTRATION_ERROR";

function confirmRegistrationStart() {
    return {
        type: CONFIRM_REGISTRATION_START
    }
}

function confirmRegistrationSuccess() {
    return {
        type: CONFIRM_REGISTRATION_SUCCESS
    }
}

function confirmRegistrationError( error ) {
    return {
        type: CONFIRM_REGISTRATION_ERROR,
        error: error
    }
}

export function confirmRegistration( guid ) {
    return dispatch => {
        dispatch( confirmRegistrationStart() );

        return DataAccess.confirmRegistration( guid )
            //.then( response => dispatch( confirmRegistrationSuccess() ) )
            .then( response => {
                dispatch( confirmRegistrationSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка подтверждения регистрации";
                dispatch( confirmRegistrationError( error ) );
            } );
    }
}
//#endregion