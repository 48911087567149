import { MarkersDataAccess } from "../DataAccess/MarkersDataAccess";
const DataAccess = new MarkersDataAccess();

export const FETCH_MARKERS_START = "FETCH_MARKERS_START";
export const FETCH_MARKERS_SUCCESS = "FETCH_MARKERS_SUCCESS";
export const FETCH_MARKERS_ERROR = "FETCH_MARKERS_ERROR";

function fetchMarkersStart() {
    return {
        type: FETCH_MARKERS_START,
    };
}

function fetchMarkersSuccess(eventMarker, newsMarkers, advMarkers) {
    return {
        type: FETCH_MARKERS_SUCCESS,
        eventMarker,
        newsMarkers,
        advMarkers,
    };
}

function fetchMarkersError(error) {
    return {
        type: FETCH_MARKERS_ERROR,
        error: error,
    };
}

export function getMarkers(eventID) {
    return dispatch => {
        dispatch(fetchMarkersStart());

        return DataAccess.fetch(eventID)
            .then(response => response.json())
            .then(json =>
                dispatch(
                    fetchMarkersSuccess(
                        json.eventMarker,
                        json.newsMarkers,
                        json.advMarkers
                    )
                )
            )
            .catch(error => {
                error.name = "Ошибка загрузки маркеров";
                dispatch(fetchMarkersError(error));
            });
    };
}

export const RESET_MARKERS = "RESET_MARKERS";

function doResetMarkers() {
    return {
        type: RESET_MARKERS,
    };
}

export function resetMarkers() {
    return dispatch => {
        dispatch(doResetMarkers());
    };
}
