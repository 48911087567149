import { EventsDataAccess } from "../DataAccess/EventsDataAccess";
const DataAccess = new EventsDataAccess();

//#region getEvents
export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

function getEventsStart( pageNumber, itemsPerPage ) {
    return {
        type: GET_EVENTS_START,
        pageNumber,
        itemsPerPage
    }
}

function getEventsSuccess( pageNumber, totalCount, events ) {
    return {
        type: GET_EVENTS_SUCCESS,
        pageNumber,
        totalCount,
        events: events
    }
}

function getEventsError( error ) {
    return {
        type: GET_EVENTS_ERROR,
        error: error
    }
}

export function getEvents( pageNumber, itemsPerPage ) {
    return dispatch => {
        dispatch( getEventsStart( pageNumber, itemsPerPage ) );

        return DataAccess.fetch( pageNumber, itemsPerPage )
            .then( response => response.json() )
            .then( json => dispatch( getEventsSuccess( json.page_number, json.total_count, json.events ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки событий";
                dispatch( getEventsError( error ) );
            } );
    }
}
//#endregion

//#region deleteEvent
export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

function deleteEventStart( eventID ) {
    return {
        type: DELETE_EVENT_START,
        eventID
    }
}

function deleteEventSuccess() {
    return {
        type: DELETE_EVENT_SUCCESS
    }
}

function deleteEventError( error ) {
    return {
        type: DELETE_EVENT_ERROR,
        error: error
    }
}

export function removeEvent( eventID ) {
    return dispatch => {
        dispatch( deleteEventStart( eventID ) );

        return DataAccess.remove( eventID )
            .then( response => {
                if( response.status < 200 || response.status >= 300 ) {
                    var error = new Error( response.statusText );
                    error.response = response;
                    throw error;
                }
                dispatch( deleteEventSuccess() );
            } )
            .catch( error => {
                dispatch( deleteEventError( error ) );
                throw error;
            } );
    };
}
//#endregion

//#region updateEvent
export const UPDATE_EVENT_START = "UPDATE_EVENT_START";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

function updateEventStart() {
    return {
        type: UPDATE_EVENT_START
    }
}

function updateEventSuccess( event ) {
    return {
        type: UPDATE_EVENT_SUCCESS,
        event: event
    }
}

function updateEventError( error ) {
    return {
        type: UPDATE_EVENT_ERROR,
        error: error
    }
}

export function updateEvent( event ) {
    return dispatch => {
        dispatch( updateEventStart() );

        return DataAccess.update( event )
            .then( () => {
                dispatch( updateEventSuccess( event ) );
            } )
            .catch( error => {
                error.name = "Ошибка обновления события";
                dispatch( updateEventError( error ) );
            } );
    }
}
//#endregion

//#region addEvent
export const ADD_EVENT_START = "ADD_EVENT_START";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_ERROR = "ADD_EVENT_ERROR";

function addEventStart() {
    return {
        type: ADD_EVENT_START
    }
}

function addEventSuccess() {
    return {
        type: ADD_EVENT_SUCCESS
    }
}

function addEventError( error ) {
    return {
        type: ADD_EVENT_ERROR,
        error: error
    }
}

export function addEvent( event ) {
    return dispatch => {
        dispatch( addEventStart() );

        return DataAccess.add( event )
            .then( () => {
                dispatch( addEventSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка добавления события";
                dispatch( addEventError( error ) );
            } );
    }
}
//#endregion

//#region resetEventsError
export const RESET_EVENTS_ERROR = "RESET_EVENTS_ERROR";

function doResetEventsError() {
    return {
        type: RESET_EVENTS_ERROR
    }
}

export function resetEventsError() {
    return dispatch => {
        dispatch( doResetEventsError() );
    }
}
//#endregion
