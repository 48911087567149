import { UserIntentionDataAccess } from "../DataAccess/UserIntentionDataAccess";
const DataAccess = new UserIntentionDataAccess();

//#region getUserIntentions
export const GET_USER_INTENTIONS_START = "GET_USER_INTENTIONS_START";
export const GET_USER_INTENTIONS_SUCCESS = "GET_USER_INTENTIONS_SUCCESS";
export const GET_USER_INTENTIONS_ERROR = "GET_USER_INTENTIONS_ERROR";

function getUserIntentionsStart() {
    return {
        type: GET_USER_INTENTIONS_START
    }
}

function getUserIntentionsSuccess( intentions ) {
    return {
        type: GET_USER_INTENTIONS_SUCCESS,
        intentions: intentions
    }
}

function getUserIntentionsError( error ) {
    return {
        type: GET_USER_INTENTIONS_ERROR,
        error: error
    }
}

export function getUserIntentions() {
    return dispatch => {
        dispatch( getUserIntentionsStart() );

        return DataAccess.fetch()
            .then( response => response.json() )
            .then( json => dispatch( getUserIntentionsSuccess( json ) ) )
            .catch( error => { 
                error.name = "Ошибка загрузки намерений пользователя";
                dispatch( getUserIntentionsError( error ) );
            } );
    }
}
//#endregion

//#region selectUserIntention
export const SELECT_USER_INTENTION_START = "SELECT_USER_INTENTION_START";
export const SELECT_USER_INTENTION_SUCCESS = "SELECT_USER_INTENTION_SUCCESS";
export const SELECT_USER_INTENTION_ERROR = "SELECT_USER_INTENTION_ERROR";

function selectUserIntentionStart() {
    return {
        type: SELECT_USER_INTENTION_START
    }
}

function selectUserIntentionSuccess( id, checked ) {
    return {
        type: SELECT_USER_INTENTION_SUCCESS,
        id,
        checked 
    }
}

function selectUserIntentionError( error ) {
    return {
        type: SELECT_USER_INTENTION_ERROR,
        error: error
    }
}

export function selectUserIntention( id, checked ) {
    return dispatch => {
        dispatch( selectUserIntentionStart() );

        return DataAccess.selectUserIntention( id, checked )
            .then( () => {
                dispatch( selectUserIntentionSuccess( id, checked ) );
            })
            .catch( error => {
                error.name = "Ошибка выбора намерения";
                dispatch( selectUserIntentionError( error ) );
            });
    }    
}
//#endregion

//#region getQuest
export const GET_QUEST_START = "GET_QUEST_START";
export const GET_QUEST_SUCCESS = "GET_QUEST_SUCCESS";
export const GET_QUEST_ERROR = "GET_QUEST_ERROR";

function getQuestStart() {
    return {
        type: GET_QUEST_START
    }
}

function getQuestSuccess( text ) {
    return {
        type: GET_QUEST_SUCCESS,
        text: text
    }
}

function getQuestError( error ) {
    return {
        type: GET_QUEST_ERROR,
        error: error
    }
}

export function getQuest( id ) {
    return dispatch => {
        dispatch( getQuestStart() );

        return DataAccess.getQuest( id )
            /*.then( response => response.json() )
            .then( json => dispatch( getUserIntentionsSuccess( json ) ) )*/
            //.then( ( response ) => dispatch( getQuestSuccess( response ) ) )
            .then( response => response.text() )
            .then( text => dispatch( getQuestSuccess( text ) ) )
            .catch( error => { 
                error.name = "Ошибка загрузки анкеты";
                dispatch( getQuestError( error ) );
            } );
    }
}
//#endregion

//#region uploadQuest
export const UPLOAD_QUEST_START = "UPLOAD_QUEST_START";
export const UPLOAD_QUEST_SUCCESS = "UPLOAD_QUEST_SUCCESS";
export const UPLOAD_QUEST_ERROR = "UPLOAD_QUEST_ERROR";

function uploadQuestStart() {
    return {
        type: UPLOAD_QUEST_START
    }
}

function uploadQuestSuccess( id ) {
    return {
        type: UPLOAD_QUEST_SUCCESS,
        id
    }
}

function uploadQuestError( error ) {
    return {
        type: UPLOAD_QUEST_ERROR,
        error: error
    }
}

export function uploadQuest( id, file ) {
    return dispatch => {
        dispatch( uploadQuestStart() );

        return DataAccess.uploadQuest( id, file )
            .then( () => {
                dispatch( uploadQuestSuccess( id ) );
            })
            .catch( error => {
                error.name = "Ошибка загрузки на сервер файла анкеты";
                dispatch( uploadQuestError( error ) );
            });
    }    
}
//#endregion
