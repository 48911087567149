import {
    FETCH_SUBSCRIPTION_START,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_SUBSCRIPTION_ERROR,
    FETCH_ISICID_START,
    FETCH_ISICID_SUCCESS,
    FETCH_ISICID_ERROR,
    EDIT_ISICID_SUCCESS
} from "../actions/subscriptionsActions";

export default function subscriptionsReducer(
    state = {
        isFetching: false,
        isSubscribed: false,
        isicID: null
    },
    action ) {
    switch( action.type ) {
        case FETCH_SUBSCRIPTION_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case FETCH_SUBSCRIPTION_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                isSubscribed: action.isSubscribed
            } )
        case FETCH_SUBSCRIPTION_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case FETCH_ISICID_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case FETCH_ISICID_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                isicID: action.isicID
            } )
        case FETCH_ISICID_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case EDIT_ISICID_SUCCESS:
            return Object.assign( {}, state, {
                isicID: parseInt( action.isicID, 10 )
            } )
        default:
            return state;
    }
}
