import UserDataAccess from "../DataAccess/UserDataAccess";
const DataAccess = new UserDataAccess();

//#region getSubscription 
export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_ERROR = "FETCH_SUBSCRIPTION_ERROR";

function fetchSubscriptionStart() {
    return {
        type: FETCH_SUBSCRIPTION_START
    }
}

function fetchSubscriptionSuccess( isSubscribed ) {
    return {
        type: FETCH_SUBSCRIPTION_SUCCESS,
        isSubscribed: isSubscribed
    }
}

function fetchSubscriptionError( error ) {
    return {
        type: FETCH_SUBSCRIPTION_ERROR,
        error: error
    }
}

export function getSubscription() {
    return dispatch => {
        dispatch( fetchSubscriptionStart() );

        return DataAccess.getUser()
            .then( response => response.json() )
            .then( json => dispatch( fetchSubscriptionSuccess( json.isSubscribed ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки признака подписки";
                dispatch( fetchSubscriptionError( error ) );
            } );
    }
}
//#endregion

//#region setSubscription 
export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";
export const EDIT_SUBSCRIPTION_ERROR = "EDIT_SUBSCRIPTION_ERROR";

function editSubscription( isSubscribed ) {
    return {
        type: EDIT_SUBSCRIPTION,
        isSubscribed: isSubscribed
    }
}

function editSubscriptionError( error ) {
    return {
        type: EDIT_SUBSCRIPTION_ERROR,
        error: error
    }
}

export function setSubscription( isSubscribed ) {
    return dispatch => {
        dispatch( editSubscription( isSubscribed ) );

        return DataAccess.setUserSubscribed( isSubscribed )
            .catch( error => {
                error.name = "Ошибка сохранения признака подписки";
                dispatch( editSubscriptionError( error ) );
            } );
    }
}
//#endregion

//#region getIsicID
export const FETCH_ISICID_START = "FETCH_ISICID_START";
export const FETCH_ISICID_SUCCESS = "FETCH_ISICID_SUCCESS";
export const FETCH_ISICID_ERROR = "FETCH_ISICID_ERROR";

function fetchIsicIDStart() {
    return {
        type: FETCH_ISICID_START
    }
}

function fetchIsicIDSuccess( isicID ) {
    return {
        type: FETCH_ISICID_SUCCESS,
        isicID: isicID
    }
}

function fetchIsicIDError( error ) {
    return {
        type: FETCH_ISICID_ERROR,
        error: error
    }
}

export function getIsicID() {
    return dispatch => {
        dispatch( fetchIsicIDStart() );

        return DataAccess.getUser()
            .then( response => response.json() )
            .then( json => dispatch( fetchIsicIDSuccess( json.isicID ) ) )
            .catch( error => {
                error.name = "Ошибка получения номера выбранной отрасли";
                dispatch( fetchIsicIDError( error ) );
            } );
    }
}
//#endregion

//#region setIsicID
export const EDIT_ISICID_START = "EDIT_ISICID_START";
export const EDIT_ISICID_SUCCESS = "EDIT_ISICID_SUCCESS";
export const EDIT_ISICID_ERROR = "EDIT_ISICID_ERROR";

function editIsicIDStart( isicID ) {
    return {
        type: EDIT_ISICID_START,
        isicID: isicID
    }
}

function editIsicIDSuccess( isicID ) {
    return {
        type: EDIT_ISICID_SUCCESS,
        isicID: isicID
    }
}

function editIsicIDError( error ) {
    return {
        type: EDIT_ISICID_ERROR,
        error: error
    }
}

export function setIsicID( isicID ) {
    return dispatch => {
        dispatch( editIsicIDStart( isicID ) );

        return DataAccess.setUserIsicID( isicID )
            .then( () => {
                dispatch( editIsicIDSuccess( isicID ) );
            } )
            .catch( error => {
                error.name = "Ошибка сохранения номера выбранной отрасли";
                dispatch( editIsicIDError( error ) );
            } );
    }
}
//#endregion
