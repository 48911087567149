import { ManageUserIntentionDataAccess } from "../DataAccess/ManageUserIntentionDataAccess";
const DataAccess = new ManageUserIntentionDataAccess();

//#region getManageUserIntentions
export const GET_MANAGE_USER_INTENTIONS_START = "GET_MANAGE_USER_INTENTIONS_START";
export const GET_MANAGE_USER_INTENTIONS_SUCCESS = "GET_MANAGE_USER_INTENTIONS_SUCCESS";
export const GET_MANAGE_USER_INTENTIONS_ERROR = "GET_MANAGE_USER_INTENTIONS_ERROR";

function getManageUserIntentionsStart() {
    return {
        type: GET_MANAGE_USER_INTENTIONS_START
    }
}

function getManageUserIntentionsSuccess( pageNumber, totalCount, intentions ) {
    return {
        type: GET_MANAGE_USER_INTENTIONS_SUCCESS,
        pageNumber,
        totalCount,
        intentions: intentions
    }
}

function getManageUserIntentionsError( error ) {
    return {
        type: GET_MANAGE_USER_INTENTIONS_ERROR,
        error: error
    }
}

export function getManageUserIntentions( pageNumber, itemsPerPage ) {
    return dispatch => {
        dispatch( getManageUserIntentionsStart() );

        return DataAccess.fetch( pageNumber, itemsPerPage )
            .then( response => response.json() )
            .then( json => dispatch( getManageUserIntentionsSuccess( json.page_number, json.total_count, json.intentions ) ) )
            .catch( error => { 
                error.name = "Ошибка загрузки намерений";
                dispatch( getManageUserIntentionsError( error ) );
            } );
    }
}
//#endregion

//#region resetManageUserIntentionsError
export const RESET_MANAGE_USER_INTENTIONS_ERROR = "RESET_MANAGE_USER_INTENTIONS_ERROR";

function doResetManageUserIntentionsError() {
    return {
        type: RESET_MANAGE_USER_INTENTIONS_ERROR
    }
}

export function resetManageUserIntentionsError() {
    return dispatch => {
        dispatch( doResetManageUserIntentionsError() );
    }    
}
//#endregion

//#region getUserQuest
export const GET_USER_QUEST_START = "GET_USER_QUEST_START";
export const GET_USER_QUEST_SUCCESS = "GET_USER_QUEST_SUCCESS";
export const GET_USER_QUEST_ERROR = "GET_USER_QUEST_ERROR";

function getUserQuestStart() {
    return {
        type: GET_USER_QUEST_START
    }
}

function getUserQuestSuccess( text ) {
    return {
        type: GET_USER_QUEST_SUCCESS,
        text: text
    }
}

function getUserQuestError( error ) {
    return {
        type: GET_USER_QUEST_ERROR,
        error: error
    }
}

export function getUserQuest( user_id, intention_id ) {
    return dispatch => {
        dispatch( getUserQuestStart() );

        return DataAccess.getUserQuest( user_id, intention_id )
            /*.then( response => response.json() )
            .then( json => dispatch( getUserIntentionsSuccess( json ) ) )*/
            //.then( ( response ) => dispatch( getQuestSuccess( response ) ) )
            .then( response => response.text() )
            .then( text => dispatch( getUserQuestSuccess( text ) ) )
            .catch( error => { 
                error.name = "Ошибка загрузки анкеты пользователя";
                dispatch( getUserQuestError( error ) );
            } );
    }
}
//#endregion

//#region setUiConfirmed
export const SET_UI_CONFIRMED_START = 'SET_UI_CONFIRMED_START';
export const SET_UI_CONFIRMED_SUCCESS = 'SET_UI_CONFIRMED_SUCCESS';
export const SET_UI_CONFIRMED_ERROR = 'SET_UI_CONFIRMED_ERROR';

function setUiConfirmedStart() {
    return {
        type: SET_UI_CONFIRMED_START
    }
}

function setUiConfirmedSuccess( user_id, intention_id, checked ) {
    return {
        type: SET_UI_CONFIRMED_SUCCESS,
        user_id,
        intention_id,
        checked 
    }
}

function setUiConfirmedError( error ) {
    return {
        type: SET_UI_CONFIRMED_ERROR,
        error: error
    }
}

export function setUiConfirmed( user_id, intention_id, checked ) {
    return dispatch => {
        dispatch( setUiConfirmedStart() );

        return DataAccess.setConfirmed( user_id, intention_id, checked )
            .then( () => {
                dispatch( setUiConfirmedSuccess( user_id, intention_id, checked ) );
            })
            .catch( error => {
                error.name = "Ошибка подтверждения намерения";
                dispatch( setUiConfirmedError( error ) );
            });
    }    
}
//#endregion

