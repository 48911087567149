import AuthService from '../components/Auth/AuthService';
const Auth = new AuthService();

export class AdsDataAccess {
    fetch = ( pageNumber, itemsPerPage ) => {
        return Auth.get( "/api/Ads", { pageNumber: pageNumber, pageSize: itemsPerPage } );
    }

    remove = ( adID ) => {
        return Auth.delete( "/api/Ads/" + adID, {} );
    }

    update = ( ad ) => {
        return Auth.put(
            "/api/Ads/" + ad.id,
            {
                description: ad.description,
                latitude: ad.latitude,
                longitude: ad.longitude,
                date_end: ad.date_end,
                name: ad.name,
                objects: ad.objects,
                place_name: ad.place_name
            }
        );
    }

    add = ( ad ) => {
        return Auth.put(
            "/api/Ads/",
            {
                description: ad.description,
                latitude: ad.latitude,
                longitude: ad.longitude,
                date_end: ad.date_end,
                name: ad.name,
                objects: ad.objects,
                place_name: ad.place_name
            }
        );
    }
}    
