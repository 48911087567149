import React, { Component } from "react";
import PageHeader from "../PageHeader";
import AreasDropdown from "./AreasDropdown";
import SearchBar from "./SearchBar";
import GridWithMap from "./GridWithMap";

class SummaryPage extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            areaID: -1,
            search: ""
        };
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader name="Здания и события" />

                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr style={{ verticalAlign: "top", height: "50px" }}>
                            <td style={{ width: "33%" }}>
                                <AreasDropdown onAreaChange={areaID => this.setState( { areaID } )} />
                            </td>
                            <td style={{ textAlign: "right", width: "33%" }}>
                                <SearchBar onSearchChange={search => this.setState( { search } )} />
                            </td>
                            <td style={{ width: "20px" }}>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr style={{ verticalAlign: "top" }}>
                            <GridWithMap
                                areaID={this.state.areaID}
                                search={this.state.search}
                            />
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default SummaryPage;
