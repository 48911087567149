import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

class FieldFilename extends Component {
    render() {
        return (
            <React.Fragment>
                <FormControl
                    {...this.props.input}
                    type="hidden"
                />

                <input type="file" accept=".htm,.html" onChange={( e ) => this.props.setFilename( e.target.files )} />
            </React.Fragment>
        );
    }
}

export default FieldFilename;
