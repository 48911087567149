import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "./ModalViewSummary.css";
import Modal from "react-responsive-modal";

class ModalViewSummary extends Component {
    render() {
        if( !this.props.show )
            return null;

        return (
            <Modal
                open={this.props.show}
                onClose={() => this.props.onMyHide()}
                classNames={{ modal: 'modal-view-summary' }}
                center
            >
                <b>Свойства события #{this.props.summary.id}</b><br /><br />

                <table className="table">
                    <tbody>
                        <tr><td><b>Дата:</b></td><td style={{ width: "100%" }}>{this.props.summary.date_created}</td></tr>
                        <tr><td><b>Место:</b></td><td>{this.props.summary.place_name}</td></tr>
                        <tr><td><b>Описание:</b></td><td>{this.props.summary.description}</td></tr>
                        <tr><td><b>Достоверность:&nbsp;&nbsp;&nbsp;</b></td><td><Form.Check type="checkbox" checked={this.props.summary.is_reliable} readOnly /></td></tr>
                        <tr><td><b>Широта:</b></td><td>{this.props.summary.latitude}</td></tr>
                        <tr><td><b>Долгота:</b></td><td>{this.props.summary.longitude}</td></tr>
                        <tr><td><b>Название места:</b></td><td>{this.props.summary.place_name}</td></tr>
                        <tr><td><b>Объекты:</b></td><td>{this.props.summary.objects}</td></tr>
                    </tbody>
                </table>
            </Modal>
        );
    }
}

export default ModalViewSummary;