import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMarkers, resetMarkers } from "../../actions/markersActions";

import { Alert } from "react-bootstrap";
import Spinner from "../Spinner";
import MapWithAMarkers from "./MapWithAMarkers";

import { Marker } from "react-google-maps";

class MapComponent extends React.PureComponent {
    constructor( props ) {
        super( props );

        this.state = {
            infoWindowsVisible: [],
        };
    }

    componentDidMount() {
        this.props.resetMarkers_();

        this.setState( {
            onInfoWindowClick: id => () => {
                let current = this.state.infoWindowsVisible[ id ];
                let a = { ...this.state.infoWindowsVisible };
                if( !current ) {
                    Object.keys( a ).forEach( function ( key ) {
                        a[ key ] = false;
                    } );
                }
                a[ id ] = !current;
                this.setState( { infoWindowsVisible: a } );
            },
        } );
    }

    handleMapMounted = map => {
        this._map = map;

        this.zoomToMarkers( this._map );
    };

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( prevProps.summaryID !== this.props.summaryID )
            this.props.getMarkers_( this.props.summaryID );
    }

    zoomToMarkers = map => {
        const bounds = new window.google.maps.LatLngBounds();

        if( map != null ) {
            let infoWindowsVisible = {};

            if( map.props.children && Array.isArray( map.props.children ) ) {
                map.props.children.forEach( child => {
                    if( Array.isArray( child ) ) {
                        //  маркеры news и adv возвращается массивами
                        child.forEach( child1 => {
                            if( child1.type === Marker ) {
                                infoWindowsVisible[ child1.key ] = false;

                                bounds.extend(
                                    new window.google.maps.LatLng(
                                        child1.props.position.lat,
                                        child1.props.position.lng
                                    )
                                );

                                //console.log("Extended for news/ad " + child1.props.position.lat + ", " + child1.props.position.lng);
                            }
                        } );
                    } else {
                        //console.log("single in foreach");
                        if( child && child.type ) {
                            //console.log(child.type);
                            //console.log(child.type === Marker);
                        }

                        //if (child.type === Marker) { // locationMarker идёт один, не в массиве
                        if(
                            child &&
                            child.type &&
                            ( child.type.name === "Marker" ||
                                child.type === Marker )
                        ) {
                            // locationMarker идёт один, не в массиве
                            infoWindowsVisible[ child.key ] = true;

                            bounds.extend(
                                new window.google.maps.LatLng(
                                    child.props.position.lat,
                                    child.props.position.lng
                                )
                            );

                            //console.log("Extended for main marker " + child.props.position.lat + ", " + child.props.position.lng);
                        }
                    }
                } );
            } else {
                //  пришёл только маркер
                let child = map.props.children;

                //console.log("single");
                if( child && child.type ) {
                    //console.log(child.type);
                    //console.log(child.type === Marker);
                }

                if(
                    child.type &&
                    ( child.type.name === "Marker" || child.type === Marker )
                ) {
                    // locationMarker идёт один, не в массиве
                    infoWindowsVisible[ child.key ] = true;

                    bounds.extend(
                        new window.google.maps.LatLng(
                            child.props.position.lat,
                            child.props.position.lng
                        )
                    );

                    //console.log("Extended for single marker " + child.props.position.lat + ", " + child.props.position.lng);
                }
            }

            map.fitBounds( bounds );

            this.setState( { infoWindowsVisible: infoWindowsVisible } );
        }
    };

    render() {
        const {
            isFetching,
            error,
            eventMarker,
            newsMarkers,
            advMarkers,
        } = this.props;

        return (
            <React.Fragment>
                {this.props.summaryID === -1 && (
                    <Alert variant="info">
                        <strong>Событие не выбрано!</strong>
                        <br />
                        Пожалуйста, выберите событие.
                    </Alert>
                )}
                {this.props.summaryID !== -1 && isFetching && <Spinner />}
                {this.props.summaryID !== -1 && !isFetching && error && (
                    <Alert variant="danger">
                        Ошибка загрузки маркеров: {error.message}.
                    </Alert>
                )}
                {this.props.summaryID !== -1 &&
                    !isFetching &&
                    !error &&
                    ( !eventMarker ||
                        ( eventMarker.latitude == null ) ||
                        ( eventMarker.longitude == null ) ) && (
                        <Alert variant="warning">
                            <strong>Нет точки!</strong>
                            <br />У выбранного события пока нет точки.
                    </Alert>
                    )}
                {this.props.summaryID !== -1 &&
                    !isFetching &&
                    !error &&
                    eventMarker &&
                    ( eventMarker.latitude != null ) &&
                    ( eventMarker.longitude != null ) && (
                        <MapWithAMarkers
                            eventMarker={eventMarker}
                            newsMarkers={newsMarkers}
                            advMarkers={advMarkers}
                            onMapMounted={this.handleMapMounted}
                            onInfoWindowClick={this.state.onInfoWindowClick}
                            zoomToMarkers={this.zoomToMarkers}
                            infoWindowsVisible={this.state.infoWindowsVisible}
                        />
                    )}
            </React.Fragment>
        );
    }
}

MapComponent.propTypes = {
    summaryID: PropTypes.number,
    eventMarker: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
        place_name: PropTypes.string,
        objects: PropTypes.string,
        description: PropTypes.string,
    } ),
    newsMarkers: PropTypes.arrayOf(
        PropTypes.shape( {
            id: PropTypes.number.isRequired,
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired,
            place_name: PropTypes.string,
            objects: PropTypes.string,
            description: PropTypes.string,
        } ).isRequired
    ),
    advMarkers: PropTypes.arrayOf(
        PropTypes.shape( {
            id: PropTypes.number.isRequired,
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired,
            place_name: PropTypes.string,
            objects: PropTypes.string,
            description: PropTypes.string,
        } ).isRequired
    ),
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string,
    } ),
    getMarkers_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { markersReducer } = state;

    const {
        isFetching,
        error,
        eventMarker,
        newsMarkers,
        advMarkers,
    } = markersReducer || {
        isFetching: true,
        error: null,
        eventMarker: null,
        newsMarkers: [],
        advMarkers: [],
    };

    return {
        isFetching,
        error,
        eventMarker,
        newsMarkers,
        advMarkers,
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getMarkers_: summaryID => {
            dispatch( getMarkers( summaryID ) );
        },
        resetMarkers_: () => {
            dispatch( resetMarkers() );
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( MapComponent );
