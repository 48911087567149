import { SummariesDataAccess } from "../DataAccess/SummariesDataAccess";
const DataAccess = new SummariesDataAccess();

export const FETCH_SUMMARIES_START = "FETCH_SUMMARIES_START";
export const FETCH_SUMMARIES_SUCCESS = "FETCH_SUMMARIES_SUCCESS";
export const FETCH_SUMMARIES_ERROR = "FETCH_SUMMARIES_ERROR";

function fetchSummariesStart() {
    return {
        type: FETCH_SUMMARIES_START
    }
}

function fetchSummariesSuccess( pageNumber, totalCount, summaries ) {
    return {
        type: FETCH_SUMMARIES_SUCCESS,
        pageNumber,
        totalCount,
        summaries: summaries
    }
}

function fetchSummariesError( error ) {
    return {
        type: FETCH_SUMMARIES_ERROR,
        error: error
    }
}

export function getSummaries( areaID, search, pageNumber, itemsPerPage ) {
    return dispatch => {
        dispatch( fetchSummariesStart() );

        return DataAccess.fetch( areaID, search, pageNumber, itemsPerPage )
            .then( response => response.json() )
            .then( json => dispatch( fetchSummariesSuccess( json.pageNumber, json.totalCount, json.summaries ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки сводки";
                dispatch( fetchSummariesError( error ) );
            } );
    }
}
