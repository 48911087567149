import React from 'react';
import PageHeader from "../../PageHeader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withAuth from '../../Auth/withAuth';
import { getUpdates, downloadUpdate } from '../../../actions/updatesActions';
import { getProfile } from '../../../actions/profileActions';
import { Alert, Table, Button } from 'react-bootstrap';
import Spinner from '../../Spinner';
import Loader from 'react-loader-advanced';
import Octicon, { CloudDownload } from "@githubprimer/octicons-react";

class UpdatesPage extends React.Component {
    constructor( props ) {
        super( props );

        this.spinner = <Spinner />;
    }

    componentDidMount() {
        this.props.getProfile_()
            .then( response => {
                if( response.profile.canGetUpdates )
                    this.props.getUpdates_();
            } );

    }

    downloadUpdate( filename ) {
        this.props.downloadUpdate_( filename )
            .then( result => {
                var fileDownload = require( "js-file-download" );
                fileDownload( result.text, filename );
            } );
    }

    render() {
        if( !this.props.profile )
            return null;

        var updates, rows;

        if( this.props.profile.canGetUpdates ) {
            updates = this.props.updates;

            rows = updates.map( ( u ) =>
                <tr key={u.id}>
                    <td align="left" style={{ whiteSpace: "nowrap" }}>{u.filename}</td>
                    <td align="left" style={{ whiteSpace: "nowrap" }}>
                        <Button variant="outline-secondary" onClick={() => this.downloadUpdate( u.filename )}>
                            <Octicon icon={CloudDownload} ariaLabel="Скачать обновление" />
                        </Button>
                    </td>
                </tr>
            );
        }

        const { isFetching, error } = this.props;

        return (
            <Loader show={isFetching} message={this.spinner}>
                <PageHeader name="Обновления" />

                {error && <Alert variant="danger">
                    {this.props.error.name}: {this.props.error.message}.
                </Alert>}

                {!this.props.profile.canGetUpdates && <Alert variant="danger">
                    Вы не можете получать обновления.
                </Alert>}

                {this.props.profile.canGetUpdates && <Table striped bordered condensed="true" hover>
                    <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>Скачать</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>}
            </Loader>
        );
    }
}

UpdatesPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string
    } ),
    updates: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number.isRequired,
        filename: PropTypes.string.isRequired,
    } ).isRequired ).isRequired,
    getUpdates_: PropTypes.func.isRequired,
    downloadUpdate_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { updatesReducer, profileReducer } = state;

    const { profile } =
        profileReducer ||
        { profile: null };
    const { isFetching, error, updates } =
        updatesReducer ||
        { isFetching: true, error: null, updates: [] };

    return { isFetching, error, updates, profile };
}

function mapDispatchToProps( dispatch ) {
    return {
        getUpdates_: () => {
            dispatch( getUpdates() );
        },
        downloadUpdate_: ( filename ) => {
            return dispatch( downloadUpdate( filename ) );
        },
        getProfile_: () => {
            return dispatch( getProfile() );
        },
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( UpdatesPage ) );
