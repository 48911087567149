import { UpdatesDataAccess } from "../DataAccess/UpdatesDataAccess";
const DataAccess = new UpdatesDataAccess();

//#region getUpdates
export const GET_UPDATES_START = "GET_UPDATES_START";
export const GET_UPDATES_SUCCESS = "GET_UPDATES_SUCCESS";
export const GET_UPDATES_ERROR = "GET_UPDATES_ERROR";

function getUpdatesStart() {
    return {
        type: GET_UPDATES_START
    }
}

function getUpdatesSuccess( updates ) {
    return {
        type: GET_UPDATES_SUCCESS,
        updates: updates
    }
}

function getUpdatesError( error ) {
    return {
        type: GET_UPDATES_ERROR,
        error: error
    }
}

export function getUpdates() {
    return dispatch => {
        dispatch( getUpdatesStart() );

        return DataAccess.fetch()
            .then( response => response.json() )
            .then( json => dispatch( getUpdatesSuccess( json ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки обновлений";
                dispatch( getUpdatesError( error ) );
            } );
    }
}
//#endregion

//#region downloadUpdate
export const DOWNLOAD_UPDATE_START = "DOWNLOAD_UPDATE_START";
export const DOWNLOAD_UPDATE_SUCCESS = "DOWNLOAD_UPDATE_SUCCESS";
export const DOWNLOAD_UPDATE_ERROR = "DOWNLOAD_UPDATE_ERROR";

function downloadUpdateStart() {
    return {
        type: DOWNLOAD_UPDATE_START
    }
}

function downloadUpdateSuccess( text ) {
    return {
        type: DOWNLOAD_UPDATE_SUCCESS,
        text: text
    }
}

function downloadUpdateError( error ) {
    return {
        type: DOWNLOAD_UPDATE_ERROR,
        error: error
    }
}

export function downloadUpdate( filename ) {
    return dispatch => {
        dispatch( downloadUpdateStart() );

        return DataAccess.downloadUpdate( filename )
            /*.then( response => response.json() )
            .then( json => dispatch( getUserIntentionsSuccess( json ) ) )*/
            //.then( ( response ) => dispatch( getQuestSuccess( response ) ) )
            .then( response => response.text() )
            .then( text => dispatch( downloadUpdateSuccess( text ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки обновления";
                dispatch( downloadUpdateError( error ) );
            } );
    }
}
//#endregion
