import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";

import areasReducer from "./areasReducer";
import summariesReducer from "./summariesReducer";
import markersReducer from "./markersReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import isicReducer from "./isicReducer";
import eventsReducer from "./eventsReducer";
import pricelistReducer from "./pricelistReducer";
import registrationReducer from "./registrationReducer";
import pressReleasesReducer from "./pressReleasesReducer";
import aboutReducer from "./aboutReducer";
import userIntentionReducer from "./userIntentionReducer";
import manageUserIntentionReducer from "./manageUserIntentionReducer";
import adsReducer from "./adsReducer";
import updatesReducer from "./updatesReducer";
import profileReducer from "./profileReducer";

const rootReducer = combineReducers( {
    form: formReducer,
    areasReducer,
    summariesReducer,
    markersReducer,
    subscriptionsReducer,
    isicReducer,
    eventsReducer,
    pricelistReducer,
    registrationReducer,
    pressReleasesReducer,
    aboutReducer,
    userIntentionReducer,
    manageUserIntentionReducer,
    adsReducer,
    updatesReducer,
    profileReducer
} )

export default rootReducer;