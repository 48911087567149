import {
    GET_USER_INTENTIONS_START,
    GET_USER_INTENTIONS_SUCCESS,
    GET_USER_INTENTIONS_ERROR,
    SELECT_USER_INTENTION_START,
    SELECT_USER_INTENTION_SUCCESS,
    SELECT_USER_INTENTION_ERROR,
    UPLOAD_QUEST_START,
    UPLOAD_QUEST_SUCCESS,
    UPLOAD_QUEST_ERROR,
} from "../actions/userIntentionActions";

export default function eventsReducer(
    state = {
        isFetching: false,
        error: null,
        intentions: [],
    }, action ) {
    switch( action.type ) {
        case GET_USER_INTENTIONS_START:
        case SELECT_USER_INTENTION_START:
        case UPLOAD_QUEST_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case UPLOAD_QUEST_SUCCESS:
            var intentions1 = state.intentions.map( i => {
                if( i.id === action.id ) {
                    return {
                        ...i,
                        is_confirmed: false,
                        //file: null
                    }
                } else return i;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                intentions: intentions1
            } )
        case GET_USER_INTENTIONS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                intentions: action.intentions
            } )
        case GET_USER_INTENTIONS_ERROR:
        case SELECT_USER_INTENTION_ERROR:
        case UPLOAD_QUEST_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case SELECT_USER_INTENTION_SUCCESS:
            var intentions2 = state.intentions.map( i => {
                if( i.id === action.id ) {
                    return {
                        ...i,
                        user_intention_id: action.checked ? action.id : null,
                        file: null
                    }
                } else return i;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                intentions: intentions2
            } )

        default:
            return state;
    }
}
