import React, { Component } from "react";
import { Alert } from 'react-bootstrap';

class AlertEntryError extends Component {
    onClose = () => {
        this.props.onMyHide();
    }

    render() {
        if( this.props.show ) {
            return (
                <Alert variant="danger" onClose={this.onClose}>
                    <h4>Ошибка входа в систему</h4>
                    <p>Имя и/или пароль не найдены. Повторите попытку или обратитесь к администратору системы.</p>
                </Alert>
            );
        }

        return null;
    }
}

export default AlertEntryError;
