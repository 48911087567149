import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tree from 'rc-tree';
import withAuth from '../../Auth/withAuth';
import Spinner from '../../Spinner';
import { Alert } from 'react-bootstrap';

import 'rc-tree/assets/index.css';

import { getIsicData } from '../../../actions/isicActions';

/*const treeData = [
  { key: '0-0', title: 'Ветвь 0-0', children:
    [
      { key: '0-0-0', title: 'Ветвь 0-0-0', children:
        [
          { key: '0-0-0-0', title: 'Ветвь 0-0-0-0' },
        ],
      },
      { key: '0-0-1', title: 'Ветвь 0-0-1', children:
          [
            { key: '0-0-1-0', title: 'Ветвь 0-0-1-0' },
            { key: '0-0-1-1', title: 'Ветвь 0-0-1-1' },
          ],
      },
    ],
  },
  { key: '0-1', title: 'Ветвь 0-1' },
  { key: '0-2', title: 'Ветвь 0-2' }
];*/

class IsicTree extends React.Component {
    constructor() {
        super();

        this.state = { checkedKeys: [] };
    }

    componentDidMount() {
        this.props.getIsicData_();
    }

    onCheck = ( checkedKeys, info ) => {
        //console.log(info.node.props.eventKey);

        var title = "";

        if( info.checked ) {
            var node = this.getNodeById( parseInt( info.node.props.eventKey, 10 ), this.props.treeData );
            if( node )
                title = node.title;
        }

        this.props.setIsicID( info.checked, info.node.props.eventKey, title );
    }

    render() {
        const { isFetching, error, treeData, isFetchingIsicID } = this.props;

        return (
            <React.Fragment>
                {( isFetching || isFetchingIsicID ) && <Spinner />}
                {!isFetching && !isFetchingIsicID && error && <Alert variant="danger">Ошибка загрузки/сохранения выбранной отрасли: {error.message}.</Alert>}
                {!isFetching && !isFetchingIsicID && !error && <React.Fragment>
                    <Tree
                        showLine
                        checkable onCheck={this.onCheck} checkedKeys={this.state.checkedKeys} defaultExpandedKeys={this.state.checkedKeys}
                        treeData={treeData}
                        autoExpandParent
                    />
                </React.Fragment>}
            </React.Fragment>
        );
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( prevState.checkedKeys !== this.state.checkedKeys )
            return;

        const { isicID } = this.props;

        if( this.props.treeData ) {
            if( isicID )
                this.setState( { checkedKeys: [ isicID.toString() ] } );
            else
                this.setState( { checkedKeys: [] } );
        }
        else
            console.log( "Not treedata" );
    }

    getNodeById( id, node ) {
        var reduce = [].reduce;

        function runner( result, node ) {
            if( result || !node ) return result;

            if( ( node.key === id ) && node )
                return node;

            var r = runner( null, node.children );  //process this nodes children
            if( r )
                return r;

            return reduce.call( Object( node ), runner, result );  //maybe this is some ArrayLike Structure
        }

        return runner( null, node );
    }
}

IsicTree.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    getIsicData_: PropTypes.func.isRequired,
    treeData: PropTypes.array,
    setIsicID: PropTypes.func.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string
    } ),
    isFetchingIsicID: PropTypes.bool
};


function mapStateToProps( state ) {
    const { isicReducer } = state;
    const { isFetching, error, treeData } = isicReducer || { isFetching: true, error: null, treeData: null };
    return { isFetching, error, treeData };
}

function mapDispatchToProps( dispatch ) {
    return {
        getIsicData_: () => {
            dispatch( getIsicData() );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( IsicTree ) );