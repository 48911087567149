import { PricelistDataAccess } from "../DataAccess/PricelistDataAccess";
const DataAccess = new PricelistDataAccess();

export const GET_PRICELIST_START = "GET_PRICELIST_START";
export const GET_PRICELIST_SUCCESS = "GET_PRICELIST_SUCCESS";
export const GET_PRICELIST_ERROR = "GET_PRICELIST_ERROR";

function getPricelistStart() {
    return {
        type: GET_PRICELIST_START
    }
}

function getPricelistSuccess( html ) {
    return {
        type: GET_PRICELIST_SUCCESS,
        html: html
    }
}

function getPricelistError( error ) {
    return {
        type: GET_PRICELIST_ERROR,
        error: error
    }
}

export function getPricelist() {
    return dispatch => {
        dispatch( getPricelistStart() );

        return DataAccess.fetch()
            .then( response => response.text() )
            .then( text => dispatch( getPricelistSuccess( text ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки прайс-листа";
                dispatch( getPricelistError( error ) );
            } );
    }
}
