import {
    GET_ABOUT_START,
    GET_ABOUT_SUCCESS,
    GET_ABOUT_ERROR,
} from "../actions/aboutActions";

export default function aboutReducer(
    state = {
        isFetching: false,
        error: null,
        html: null
    }, action ) {
    switch( action.type ) {
        case GET_ABOUT_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case GET_ABOUT_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                html: action.html
            } )
        case GET_ABOUT_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
