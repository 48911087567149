import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SummaryPage from "./Summary/SummaryPage";

import ProfilePage from "./Cabinet/Profile/ProfilePage";
import IsicPage from "./Cabinet/Isic/IsicPage";
import UserIntentionPage from "./Cabinet/UserIntention/UserIntentionPage";
import UpdatesPage from "./Cabinet/Updates/UpdatesPage";

import EventsPage from "./Events/EventsPage";
import Login from "./Auth/Login";
import PricelistPage from "./Pricelist/PricelistPage";
import RegistrationPage from "./Registration/RegistrationPage";
import ConfirmRegistrationPage from "./Registration/ConfirmRegistrationPage";
import PressReleasesPage from "./PressReleases/PressReleasesPage";
import AboutPage from "./About/AboutPage";
import ManageUserIntentionPage from "./ManageUserIntention/ManageUserIntentionPage";
/**/
//import AdsPage from "./Ads/AdsPage";
/**/

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
    <div className="divMain">
        <main>
            <Switch>
                <Route exact path="/" component={SummaryPage} />

                <Route exact path="/Cabinet_Profile" component={ProfilePage} />
                <Route exact path="/Cabinet_Isic" component={IsicPage} />
                <Route exact path="/Cabinet_UserIntention" component={UserIntentionPage} />
                <Route exact path="/Cabinet_Updates" component={UpdatesPage} />

                <Route exact path="/Events" component={EventsPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path='/Pricelist' component={PricelistPage} />
                <Route exact path='/register' component={RegistrationPage} />
                <Route exact path='/confirmRegistration' component={ConfirmRegistrationPage} />
                <Route exact path='/PressReleases' component={PressReleasesPage} />
                <Route exact path='/About' component={AboutPage} />
                <Route exact path='/ManageUserIntention' component={ManageUserIntentionPage} />
                {/*<Route exact path="/Ads" component={AdsPage} />*/}
                <Redirect from="*" to="/" />
            </Switch>
        </main>
    </div>
);

export default Main;
