import React from "react";
import { Table, Alert, Form, Button } from "react-bootstrap";
import ModalEditEvent from "./ModalEditEvent";
import "./EventsGrid.css";
import Dialog from "react-bootstrap-dialog";
import PropTypes from "prop-types";
import Octicon, { KebabHorizontal, X } from '@githubprimer/octicons-react'

class EventsGrid extends React.Component {
    constructor( props ) {
        super( props );

        this.state = { ifShowModal: false };
    }

    closeModal = () => this.setState( { ifShowModal: false } );

    onSaveEvent = event => {
        this.closeModal();

        if( event.id !== -1 ) this.props.onUpdateEvent( event );
        else this.props.onAddEvent( event );
    };

    onEditClick = event => {
        this.setState( { currentEvent: event, ifShowModal: true } );
    };

    removeEvent = id => {
        this.dialog.show( {
            title: "Подтверждение",
            body: "Вы действительно хотите удалить событие?",
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction( () => {
                    this.props.onRemoveEvent( id );
                } ),
            ]
        } );
    };

    addEvent = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();

        if( dd < 10 ) dd = "0" + dd;
        if( mm < 10 ) mm = "0" + mm;

        today = yyyy + "-" + mm + "-" + dd;

        var event = {
            address: "",
            appointment: "",
            area_id: 1,
            date_created: today,
            description: "",
            id: -1,
            area_name: "",
            is_reliable: false,
            isic_id: null,
            isic_name: "",
            latitude: null,
            longitude: null,
            objects: "",
            place_name: "",
            can_edit: true,
        };

        this.setState( { currentEvent: event, ifShowModal: true } );
    };

    render() {
        let events = this.props.events;

        if( !events )
            return (
                <React.Fragment>
                    <table>
                        <tr>
                            <td>
                                <div className="floatRight margin14">
                                    <Button variant="primary" onClick={this.addEvent}>Добавить...</Button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Alert variant="info">
                                    <strong>
                                        Сервер не вернул ни одного события!
                                    </strong>
                                </Alert>
                            </td>
                        </tr>
                    </table>
                    <ModalEditEvent
                        show={this.state.ifShowModal}
                        onMyHide={this.closeModal}
                        event={this.state.currentEvent}
                        onSubmit={this.onSaveEvent}
                    />
                </React.Fragment>
            );

        let rows = events.map( event => (
            <tr
                key={event.id}
                className={event.latitude && event.longitude ? "" : "red"}
            >
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {event.id}
                </td>
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {event.date_created}
                </td>
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {event.area_name}
                </td>
                <td align="left">{event.description}</td>
                <td align="center">
                    <Form.Check type="checkbox" checked={event.is_reliable} readOnly />
                </td>
                <td align="center">
                    {event.can_edit && (
                        <React.Fragment>
                            <Button variant="outline-secondary" size="sm" onClick={() => this.onEditClick( event )}>
                                <Octicon icon={KebabHorizontal} ariaLabel="Редактировать" />
                            </Button>
                            &nbsp;
                            <Button variant="outline-secondary" size="sm" onClick={() => this.removeEvent( event.id )}>
                                <Octicon icon={X} ariaLabel="Удалить" />
                            </Button>
                        </React.Fragment>
                    )}
                </td>
            </tr>
        ) );

        return (
            <React.Fragment>
                <div className="floatRight margin14">
                    <Button variant="primary" onClick={this.addEvent}>
                        Добавить...
                    </Button>
                </div>
                <div>
                    <Table striped bordered condensed="true" hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Дата</th>
                                <th>Место</th>
                                <th>Описание</th>
                                <th>Достоверность</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    <ModalEditEvent
                        show={this.state.ifShowModal}
                        onMyHide={this.closeModal}
                        event={this.state.currentEvent}
                        onSubmit={this.onSaveEvent}
                    />
                    <Dialog
                        ref={el => {
                            this.dialog = el;
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

EventsGrid.propTypes = {
    /*
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string
    }),*/
    onRemoveEvent: PropTypes.func.isRequired,
    onUpdateEvent: PropTypes.func.isRequired,
    onAddEvent: PropTypes.func.isRequired,
};

export default EventsGrid;
