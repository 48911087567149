import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import Octicon, { Alert } from "@githubprimer/octicons-react";

class Register extends Component {
    renderField = ( { input, label, type, meta: { touched, error, warning, invalid } } ) => {
        const inputType = <input {...input} placeholder={label} type={type} className={`form-control ${ touched && invalid ? 'invalid' : '' }`} />;

        return (
            <div className="table-row">
                {/*<label className="table-cell">{label}</label>
                &nbsp;&nbsp;&nbsp;*/}
                <div className="table-cell-100" style={{ display: "table-cell", padding: "2px", width: "100%" }}>
                    {touched && ( ( error && <span className="error"><Octicon icon={Alert} ariaLabel="Ошибка" />&nbsp;{error}</span> ) || ( warning && <span>{warning}</span> ) )}
                    {inputType}
                </div>
            </div>
        );
    };

    render() {
        const { handleSubmit, pristine, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div style={{ display: "table", width: "100%", borderSpacing: "2px", marginTop: "25px", marginBottom: "25px" }}>
                    <Field name="email" component={this.renderField} type="email" label="e-mail" />
                    <Field name="password" component={this.renderField} type="password" label="Пароль" />
                    <Field name="confirmPassword" component={this.renderField} type="password" label="Подтвердите&nbsp;пароль" />
                    <div className="center">
                        <Button type="submit" variant="primary" disabled={pristine || submitting}>Зарегистрироваться</Button>
                    </div>
                </div>
            </form>
        );
    }
}

Register.propTypes = {
    user: PropTypes.shape( {
        email: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string
    } ).isRequired,

    onSubmit: PropTypes.func.isRequired
}


const validate = values => {
    const errors = {};

    if( !values.email )
        errors.email = "Выберите e-mail";
    else if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( values.email ) )
        errors.email = 'Неправильный формат e-mail';

    if( !values.password ) errors.password = "Введите пароль";
    if( !values.confirmPassword ) errors.confirmPassword = "Подтвердите пароль";

    if( values.password && values.confirmPassword && ( values.password !== values.confirmPassword ) )
        errors.confirmPassword = "Пароли не соответствуют";

    return errors;
}

function mapStateToProps( state, ownProps ) {
    if( !ownProps.user )
        return {};

    return {
        initialValues: { ...ownProps.user }
    };
}

export default connect( mapStateToProps, null )( reduxForm( {
    form: 'register',
    enableReinitialize: true,
    validate
} )( Register ) );