import AuthService from "../components/Auth/AuthService";
const Auth = new AuthService();

export default class UserDataAccess {
    getUser = () => {
        return Auth.get( "/api/User", {} );
    };

    setUserSubscribed = ( isSubscribed ) => {
        return Auth.post( "/api/User/SetUserSubscribed", { IsSubscribed: isSubscribed } );
    };

    setUserIsicID = ( isicID ) => {
        return Auth.post( "/api/User/SetUserIsicID", { IsicID: isicID } );
    };
}
