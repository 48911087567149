import AuthService from "../components/Auth/AuthService";
const Auth = new AuthService();

export class UpdatesDataAccess {
    fetch = () => {
        return Auth.get( "/api/Updates", {} );
    }

    downloadUpdate = ( filename ) => {
        return Auth.get( "/api/Updates/" + filename, {} );
    }
}    
