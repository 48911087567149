import { AreasDataAccess } from "../DataAccess/AreasDataAccess";
const DataAccess = new AreasDataAccess();

export const FETCH_AREAS_START = "FETCH_AREAS_START";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_ERROR = "FETCH_AREAS_ERROR";

function fetchAreasStart() {
    return {
        type: FETCH_AREAS_START
    }
}

function fetchAreasSuccess( json ) {
    return {
        type: FETCH_AREAS_SUCCESS,
        areas: json
    }
}

function fetchAreasError( error ) {
    return {
        type: FETCH_AREAS_ERROR,
        error: error
    }
}

export function getAreas() {
    return dispatch => {
        dispatch( fetchAreasStart() );

        return DataAccess.fetch()
            .then( response => response.json() )
            .then( json => dispatch( fetchAreasSuccess( json ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки территорий";
                dispatch( fetchAreasError( error ) );
            } );
    }
}
