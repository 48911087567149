import AuthService from '../components/Auth/AuthService';
const Auth = new AuthService();

export class EventsDataAccess {
    fetch = ( pageNumber, itemsPerPage ) => {
        return Auth.get( "/api/Events", { pageNumber: pageNumber, pageSize: itemsPerPage } );
    }

    remove = ( eventID ) => {
        return Auth.delete( "/api/Events/" + eventID, {} );
    }

    update = ( event ) => {
        return Auth.put(
            "/api/Events/" + event.id,
            {
                address: event.address,
                purpose: event.purpose,
                description: event.description,
                place_name: event.place_name,
                is_reliable: event.is_reliable,
                isic_id: event.isic_id,
                latitude: event.latitude,
                longitude: event.longitude,
                objects: event.objects,
                area_id: event.area_id
            }
        );
    }

    add = ( event ) => {
        return Auth.put(
            "/api/Events/",
            {
                address: event.address,
                purpose: event.purpose,
                description: event.description,
                place_name: event.place_name,
                is_reliable: event.is_reliable,
                isic_id: event.isic_id,
                latitude: event.latitude,
                longitude: event.longitude,
                objects: event.objects,
                area_id: event.area_id
            }
        );
    }
}    
