import decode from "jwt-decode";

export default class AuthService {
    login = ( username, password ) => {
        // Get a token
        return this.post( "/api/Token", { username, password } )
            .then( r => r.json() )
            .then( res => {
                this.setToken( res.token );
                return Promise.resolve( res );
            } );
    };

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        let result = !!token && !this.isTokenExpired( token ); // handwaiving here
        return result;
    }

    getUserRole() {
        const token = this.getToken();

        if( !!token && !this.isTokenExpired( token ) ) {
            const decoded = decode( token );
            return decoded.Role;
        }

        return null;
    }

    canEdit() {
        if( !this.loggedIn() )
            return false;

        var role = this.getUserRole();

        return role === "Editor" || role === "Admin";
    }

    isAdmin() {
        if( !this.loggedIn() )
            return false;

        var role = this.getUserRole();

        return role === "Admin";
    }

    getUserEmail() {
        const token = this.getToken();

        if( !!token && !this.isTokenExpired( token ) ) {
            const decoded = decode( token );
            return decoded.Email;
        }

        return null;
    }

    isTokenExpired( token ) {
        try {
            const decoded = decode( token );
            if( decoded.exp < Date.now() / 1000 )
                return true;
            else
                return false;
        } catch( err ) {
            return false;
        }
    }

    setToken( idToken ) {
        // Saves user token to localStorage
        localStorage.setItem( "id_token", idToken );
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem( "id_token" );
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem( "id_token" );
    }

    getProfile = () => {
        return decode( this.getToken() );
    }

    getQueryString( params ) {
        var esc = encodeURIComponent;

        return Object.keys( params )
            .map( k => esc( k ) + "=" + esc( params[ k ] ) )
            .join( "&" );
    }

    request( path, method, params ) {
        var qs = "";
        var body;
        var headers = params.headers || {
            Accept: "application/json",
            "Content-Type": "application/json"
        };

        if( this.loggedIn() )
            headers[ "Authorization" ] = "Bearer " + this.getToken();

        if( [ "GET", "DELETE" ].indexOf( method ) > -1 ) {
            if( params )
                qs = "?" + this.getQueryString( params );
        } else { // POST or PUT
            if( params )
                body = params.body || JSON.stringify( params );
        }

        var url = process.env.REACT_APP_API_BASE_URL + path + qs;

        let promise = new Promise( ( resolve, reject ) => {
            return fetch( url, { method, headers, body } ).then(
                response => {
                    if( response.ok )
                        resolve( response );
                    else {
                        response.text().then( t => {
                            var error = new Error(
                                "Статус: " +
                                response.status.toString() +
                                ", текст статуса: " +
                                response.statusText +
                                ", внутренняя ошибка: " +
                                t
                            );
                            error.response = response;
                            reject( new Error( error ) );
                        } );
                    }
                },
                error => {
                    reject( new Error( error.message ) );
                }
            );
        } );

        return promise;
    }

    get( path, params ) { return this.request( path, "GET", params ); }
    post( path, params ) { return this.request( path, "POST", params ); }
    put( path, params ) { return this.request( path, "PUT", params ); }
    delete( path, params ) { return this.request( path, "DELETE", params ); }
}
