import {
    GET_EVENTS_START,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_ERROR,
    DELETE_EVENT_START,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    UPDATE_EVENT_START,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_ERROR,
    ADD_EVENT_START,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_ERROR,
    RESET_EVENTS_ERROR
} from "../actions/eventsActions";

export default function eventsReducer(
    state = {
        isFetching: false,
        error: null,
        pageNumber: 1,
        totalCount: 0,
        events: [],
    }, action ) {
    switch( action.type ) {
        case GET_EVENTS_START:
        case DELETE_EVENT_START:
        case UPDATE_EVENT_START:
        case ADD_EVENT_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case GET_EVENTS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                pageNumber: action.pageNumber,
                totalCount: action.totalCount,
                events: action.events
            } )
        case GET_EVENTS_ERROR:
        case DELETE_EVENT_ERROR:
        case UPDATE_EVENT_ERROR:
        case ADD_EVENT_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case DELETE_EVENT_SUCCESS:
        case ADD_EVENT_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false
            } )
        case UPDATE_EVENT_SUCCESS:
            /*return [
                ...state.filter(events => event.id !== action.event.id),
                Object.assign({}, action.cat)
            ];*/
            /*...state.filter(cat => cat.id !== action.cat.id),
            Object.assign({}, action.cat) */
            //console.log( action );

            var events = state.events.map( event => {
                if( event.id === action.event.id ) {
                    return {
                        /*...event,
                        description: action.description,*/
                        ...action.event
                        /*title: action.data.newTitle,
                        message:action.data.newMessage,
                        editing: !post.editing*/
                    }
                } else return event;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                events
            } )
        /*
case 'UPDATE':
  return state.map((post)=>{
    if(post.id === action.id) {
      return {
         ...post,
         title:action.data.newTitle,
         message:action.data.newMessage,
         editing: !post.editing
      }
    } else return post;
  })            */
        case RESET_EVENTS_ERROR:
            return Object.assign( {}, state, {
                error: null
            } )
        default:
            return state;
    }
}
