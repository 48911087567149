import {
    GET_ADS_START,
    GET_ADS_SUCCESS,
    GET_ADS_ERROR,
    DELETE_AD_START,
    DELETE_AD_SUCCESS,
    DELETE_AD_ERROR,
    UPDATE_AD_START,
    UPDATE_AD_SUCCESS,
    UPDATE_AD_ERROR,
    ADD_AD_START,
    ADD_AD_SUCCESS,
    ADD_AD_ERROR,
    RESET_ADS_ERROR
} from "../actions/adsActions";

export default function adsReducer(
    state = {
        isFetching: false,
        error: null,
        pageNumber: 1,
        totalCount: 0,
        ads: [],
    }, action ) {
    switch( action.type ) {
        case GET_ADS_START:
        case DELETE_AD_START:
        case UPDATE_AD_START:
        case ADD_AD_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case GET_ADS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                pageNumber: action.pageNumber,
                totalCount: action.totalCount,
                ads: action.ads
            } )
        case GET_ADS_ERROR:
        case DELETE_AD_ERROR:
        case UPDATE_AD_ERROR:
        case ADD_AD_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case DELETE_AD_SUCCESS:
        case ADD_AD_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false
            } )
        case UPDATE_AD_SUCCESS:
            /*return [
                ...state.filter(events => event.id !== action.event.id),
                Object.assign({}, action.cat)
            ];*/
            /*...state.filter(cat => cat.id !== action.cat.id),
            Object.assign({}, action.cat) */
            //console.log( action );

            var ads = state.ads.map( ad => {
                if( ad.id === action.ad.id ) {
                    return {
                        /*...event,
                        description: action.description,*/
                        ...action.ad
                        /*title: action.data.newTitle,
                        message:action.data.newMessage,
                        editing: !post.editing*/
                    }
                } else return ad;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                ads
            } )
        /*
case 'UPDATE':
  return state.map((post)=>{
    if(post.id === action.id) {
      return {
         ...post,
         title:action.data.newTitle,
         message:action.data.newMessage,
         editing: !post.editing
      }
    } else return post;
  })            */
        case RESET_ADS_ERROR:
            return Object.assign( {}, state, {
                error: null
            } )
        default:
            return state;
    }
}
