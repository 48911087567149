import React from "react";
import { Table, Alert, Form } from "react-bootstrap";
import ModalViewSummary from "./ModalViewSummary";
import "./SummaryGrid.css";

class SummaryGrid extends React.Component {
    constructor( props ) {
        super( props );

        this.state = { ifShowModal: false };
    }

    onDoubleClick = summary => {
        this.setState( { currentSummary: summary, ifShowModal: true } );
    };

    onClick = summaryID => {
        this.props.onSummaryClick( summaryID );

        let a = { ...this.state.summariesSelected };
        for( var key in a ) a[ key ] = false;
        a[ summaryID ] = true;
        this.setState( { summariesSelected: a } );
    };

    componentDidMount() {
        let summariesSelected = {};

        var firstID = -1;

        if( this.props.summaries && this.props.summaries.length !== 0 ) {
            this.props.summaries.forEach( summary => {
                summariesSelected[ summary.id ] = false;

                if( firstID === -1 ) firstID = summary.id;
            } );
        }

        this.setState( { summariesSelected: summariesSelected } );

        if( firstID !== -1 ) this.onClick( firstID );
    }

    render() {
        let doCloseModal = () => this.setState( { ifShowModal: false } );

        let summaries = this.props.summaries;

        if( !summaries || summaries.length === 0 )
            return (
                <Alert variant="info">
                    <strong>Сервер не вернул ни одного события!</strong>
                    <br />
                    Пожалуйста, уточните запрос.
                </Alert>
            );

        let rows = summaries.map( summary => (
            <tr
                key={summary.id}
                className={
                    this.state.summariesSelected &&
                        this.state.summariesSelected[ summary.id ]
                        ? "selected"
                        : ( ( summary.latitude != null && summary.longitude != null ) ? "" : "red" )
                }
                onClick={() => this.onClick( summary.id )}
                onDoubleClick={() => this.onDoubleClick( summary )}
            >
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {summary.date_created}
                </td>
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {summary.area_name}
                </td>
                <td align="left">
                    {summary.description}
                    {summary.external_link && summary.external_link.length > 0 &&
                        <span>
                            &nbsp;
                            <span dangerouslySetInnerHTML={{ __html: summary.external_link }} />
                        </span>
                    }
                </td>
                <td align="center">
                    <Form.Check
                        type="checkbox"
                        checked={summary.is_reliable}
                        readOnly={true}
                    />
                </td>
            </tr >
        ) );

        return (
            <div>
                <Table striped bordered condensed="true" hover>
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Территория</th>
                            <th>Описание</th>
                            <th>Достоверность</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
                <ModalViewSummary
                    show={this.state.ifShowModal}
                    onMyHide={doCloseModal}
                    summary={this.state.currentSummary}
                />
            </div>
        );
    }
}

export default SummaryGrid;
