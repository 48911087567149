import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import "./ModalEditPressRelease.css";
import PropTypes from 'prop-types';
import FieldFilename from "./FieldFilename";
import Octicon, { Alert } from "@githubprimer/octicons-react";

class ModalEditPressRelease extends Component {
    renderField = ( { input, label, type, textarea, meta: { touched, error, warning, invalid } } ) => {
        const textareaType = <textarea {...input} placeholder={label} type={type} className={`form-control ${ touched && invalid ? 'invalid' : '' }`} />;
        const inputType = <input {...input} placeholder={label} type={type} className={`form-control ${ touched && invalid ? 'invalid' : '' }`} />;

        return (
            <div className="table-row">
                <label className="table-cell">{label}</label>
                &nbsp;&nbsp;&nbsp;
                <div className="table-cell-100" style={{ display: "table-cell", padding: "2px", width: "100%" }}>
                    {touched && ( ( error && <span className="error"><Octicon icon={Alert} ariaLabel="Ошибка" />&nbsp;{error}</span> ) || ( warning && <span>{warning}</span> ) )}
                    {textarea ? textareaType : inputType}
                </div>
            </div>
        );
    };

    setFilename = ( filename ) => {
        this.props.change( "filename", filename );
    }

    render() {
        if( !this.props.show )
            return null;

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <Modal
                open={this.props.show}
                onClose={this.props.onClose}
                classNames={{ modal: 'modal-edit-pressrelease' }}
                center
            >
                <form onSubmit={handleSubmit}>
                    <div style={{ display: "table", width: "100%", borderSpacing: "2px", marginTop: "25px", marginBottom: "25px" }}>
                        <div className="table-row">
                            <label className="table-cell">Дата&nbsp;изменения</label>
                            &nbsp;&nbsp;&nbsp;
                            <p style={{ display: "table-cell", padding: "2px", width: "100%" }}>{this.props.pressRelease.date_modified}</p>
                        </div>
                        <Field name="text" component={this.renderField} type="text" label="Текст" textarea={true} />

                        <div className="table-row" >
                            <label className="table-cell">Файл</label>
                            &nbsp;&nbsp;&nbsp;
                            <div className="form-inline" style={{ display: "table-cell", padding: "2px", width: "100%" }}>
                                <Field name="filename" component={FieldFilename} setFilename={this.setFilename} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr />
                        <span className="floatLeft">
                            <Button type="button" disabled={pristine || submitting} onClick={reset}>Сброс</Button>
                        </span>
                        <span className="floatRight">
                            <Button type="submit" variant="primary" disabled={pristine || submitting}>Сохранить</Button>
                            &nbsp;&nbsp;
                            <Button onClick={this.handleClose}>Отмена</Button>
                        </span>
                    </div>
                </form>
            </Modal>
        );
    }
}

ModalEditPressRelease.propTypes = {
    pressRelease: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        date_modified: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        filename: PropTypes.string
    } ),

    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
}

function mapStateToProps( state, ownProps ) {
    if( !ownProps.pressRelease )
        return {};

    //console.log(ownProps.pressRelease);

    return {
        initialValues: { ...ownProps.pressRelease }
    };
}

const validate = values => {
    const errors = {};

    if( !values.text ) errors.isic_name = "Введите текст";

    return errors;
}

export default connect( mapStateToProps, null )( reduxForm( {
    form: 'modalEditPressRelease',
    enableReinitialize: true,
    validate
} )( ModalEditPressRelease ) );