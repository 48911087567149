import React from 'react';
import PageHeader from "../../PageHeader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withAuth from '../../Auth/withAuth';
import { getUserIntentions, selectUserIntention, getQuest, uploadQuest } from '../../../actions/userIntentionActions';
import { Alert, Table, Button } from 'react-bootstrap';
import Spinner from '../../Spinner';
import Loader from 'react-loader-advanced';
import Modal from 'react-responsive-modal';
import Octicon, { CloudDownload } from "@githubprimer/octicons-react";
import "./UserIntentionPage.css";

import Form from 'react-bootstrap/Form';

class UserIntentionPage extends React.Component {
    constructor( props ) {
        super( props );

        this.spinner = <Spinner />;
        this.state = { showModal: false };
    }

    componentDidMount() {
        this.props.getUserIntentions_();
    }

    selectIntention( id, event ) {
        this.props.selectUserIntention_( id, event.target.checked );
    }

    getQuest( id, name ) {
        this.props.getQuest_( id )
            .then( result => {
                var fileDownload = require( "js-file-download" );
                fileDownload( result.text, name + ".csv" );
            } );
    }

    uploadQuest( id, file ) {
        this.props.uploadQuest_( id, file )
            .then( () => this.setState( { showModal: true } ) );
    }

    render() {
        let intentions = this.props.intentions;

        let rows = intentions.map( ( i ) =>
            <tr key={i.id}>
                <td align="left" style={{ whiteSpace: "nowrap" }}>{i.id}</td>
                <td align="left" style={{ whiteSpace: "nowrap" }}>{i.name}</td>
                <td align="center">
                    <Form.Check
                        type="checkbox"
                        checked={i.user_intention_id !== null}
                        onChange={( e ) => this.selectIntention( i.id, e )}
                    />
                </td>
                <td align="left" style={{ whiteSpace: "nowrap" }}>
                    {i.user_intention_id &&
                        <Button variant="outline-secondary" onClick={() => this.getQuest( i.id, i.name )}>
                            <Octicon icon={CloudDownload} ariaLabel="Скачать бланк анкеты" />
                        </Button>}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                    {i.user_intention_id &&
                        <input
                            type="file"
                            accept=".csv"
                            onChange={( e ) => this.uploadQuest( i.id, e.target.files[ 0 ] )}
                        />}
                </td>
                <td align="center">
                    <Form.Check
                        type="checkbox"
                        checked={i.is_confirmed}
                        disabled={true}
                        className="disabledCheckBox"
                    />
                </td>
            </tr>
        );

        const { isFetching, error } = this.props;

        return (
            <Loader show={isFetching} message={this.spinner}>
                <PageHeader name="Намерения пользователя" />

                {error && <Alert variant="danger">
                    {this.props.error.name}: {this.props.error.message}.
                </Alert>}

                <Table striped bordered condensed="true" hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Наименование</th>
                            <th>Выбрать</th>
                            <th>Скачать бланк анкеты</th>
                            <th>Загрузить анкету</th>
                            <th>Одобрено</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>

                <Modal
                    open={this.state.showModal}
                    onClose={() => this.setState( { showModal: false } )}
                    classNames={{ modal: 'modal-quest-uploaded' }}
                    center
                >
                    Спасибо, анкета отправлена. Ваше намерение будет одобрено после рассмотрения администратором.
                </Modal>

            </Loader>
        );
    }
}

UserIntentionPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string
    } ),
    intentions: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        registration_date: PropTypes.string,
        user_intention_id: PropTypes.number,
        is_confirmed: PropTypes.bool,
    } ).isRequired ).isRequired,
    getUserIntentions_: PropTypes.func.isRequired,
    selectUserIntention_: PropTypes.func.isRequired,
    getQuest_: PropTypes.func.isRequired,
    uploadQuest_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { userIntentionReducer } = state;

    const { isFetching, error, intentions } =
        userIntentionReducer ||
        { isFetching: true, error: null, intentions: [] };

    return { isFetching, error, intentions };
}

function mapDispatchToProps( dispatch ) {
    return {
        getUserIntentions_: () => {
            dispatch( getUserIntentions() );
        },
        selectUserIntention_: ( id, checked ) => {
            return dispatch( selectUserIntention( id, checked ) );
        },
        getQuest_: ( id ) => {
            return dispatch( getQuest( id ) );
        },
        uploadQuest_: ( id, file ) => {
            return dispatch( uploadQuest( id, file ) );
        },
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( UserIntentionPage ) );
