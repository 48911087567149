import {
    GET_PRICELIST_START,
    GET_PRICELIST_SUCCESS,
    GET_PRICELIST_ERROR,
} from "../actions/pricelistActions";

export default function eventsReducer(
    state = {
        isFetching: false,
        errorFetching: null,
        html: null
    }, action ) {
    switch( action.type ) {
        case GET_PRICELIST_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case GET_PRICELIST_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                html: action.html
            } )
        case GET_PRICELIST_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
