import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import "./ModalViewFile.css";
import PropTypes from 'prop-types';
import { getPressReleaseFile } from '../../actions/pressReleasesActions';

class ModalViewFile extends Component {
    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( this.props.filename && ( prevProps.filename !== this.props.filename ) )
            this.props.getPressReleaseFile_( this.props.filename );
    }



    render() {
        if( !this.props.show )
            return null;

        return (
            <Modal
                open={this.props.show}
                onClose={this.props.onClose}
                classNames={{ modal: 'modal-view-file' }}
                center >
                {/*<img src={'http://localhost:50124/api/PressRelease/GetFile/' + this.props.filename} alt="" className="imgScale" />*/}
                <div dangerouslySetInnerHTML={{ __html: this.props.html }} />
            </Modal>
        );
    }
}

ModalViewFile.propTypes = {
    filename: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool,
    errorFetching: PropTypes.bool,
    html: PropTypes.string,
    getPressReleaseFile_: PropTypes.func.isRequired,
}

function mapStateToProps( state ) {
    const { pressReleasesReducer } = state;

    const {
        isFetching, errorFetching, html } = pressReleasesReducer || {
            isFetching: true, errorFetching: null, html: null
        };

    return {
        isFetching, errorFetching,
        html
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getPressReleaseFile_: ( filename ) => {
            dispatch( getPressReleaseFile( filename ) );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( ModalViewFile );
