import React from "react";
import TopMenu from "./components/TopMenu";
import Main from "./components/Main";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

const App = () => (
    <BrowserRouter basename="/">
        <div className="App">
            <TopMenu />
            <Main />
        </div>
    </BrowserRouter>
)

export default App;