import { AdsDataAccess } from "../DataAccess/AdsDataAccess";
const DataAccess = new AdsDataAccess();

//#region getAds
export const GET_ADS_START = "GET_ADS_START";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const GET_ADS_ERROR = "GET_ADS_ERROR";

function getAdsStart( pageNumber, itemsPerPage ) {
    return {
        type: GET_ADS_START,
        pageNumber,
        itemsPerPage
    }
}

function getAdsSuccess( pageNumber, totalCount, ads ) {
    return {
        type: GET_ADS_SUCCESS,
        pageNumber,
        totalCount,
        ads: ads
    }
}

function getAdsError( error ) {
    return {
        type: GET_ADS_ERROR,
        error: error
    }
}

export function getAds( pageNumber, itemsPerPage ) {
    return dispatch => {
        dispatch( getAdsStart( pageNumber, itemsPerPage ) );

        return DataAccess.fetch( pageNumber, itemsPerPage )
            .then( response => response.json() )
            .then( json => dispatch( getAdsSuccess( json.page_number, json.total_count, json.ads ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки реклам";
                dispatch( getAdsError( error ) );
            } );
    }
}
//#endregion

//#region deleteAd
export const DELETE_AD_START = "DELETE_AD_START";
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS";
export const DELETE_AD_ERROR = "DELETE_AD_ERROR";

function deleteAdStart( adID ) {
    return {
        type: DELETE_AD_START,
        adID
    }
}

function deleteAdSuccess() {
    return {
        type: DELETE_AD_SUCCESS
    }
}

function deleteAdError( error ) {
    return {
        type: DELETE_AD_ERROR,
        error: error
    }
}

export function removeAd( adID ) {
    return dispatch => {
        dispatch( deleteAdStart( adID ) );

        return DataAccess.remove( adID )
            .then( response => {
                if( response.status < 200 || response.status >= 300 ) {
                    var error = new Error( response.statusText );
                    error.response = response;
                    throw error;
                }
                dispatch( deleteAdSuccess() );
            } )
            .catch( error => {
                dispatch( deleteAdError( error ) );
                throw error;
            } );
    };
}
//#endregion

//#region updateAd
export const UPDATE_AD_START = "UPDATE_AD_START";
export const UPDATE_AD_SUCCESS = "UPDATE_AD_SUCCESS";
export const UPDATE_AD_ERROR = "UPDATE_AD_ERROR";

function updateAdStart() {
    return {
        type: UPDATE_AD_START
    }
}

function updateAdSuccess( ad ) {
    return {
        type: UPDATE_AD_SUCCESS,
        ad: ad
    }
}

function updateAdError( error ) {
    return {
        type: UPDATE_AD_ERROR,
        error: error
    }
}

export function updateAd( ad ) {
    return dispatch => {
        dispatch( updateAdStart() );

        return DataAccess.update( ad )
            .then( () => {
                dispatch( updateAdSuccess( ad ) );
            } )
            .catch( error => {
                error.name = "Ошибка обновления рекламы";
                dispatch( updateAdError( error ) );
            } );
    }
}
//#endregion

//#region addAd
export const ADD_AD_START = "ADD_AD_START";
export const ADD_AD_SUCCESS = "ADD_AD_SUCCESS";
export const ADD_AD_ERROR = "ADD_AD_ERROR";

function addAdStart() {
    return {
        type: ADD_AD_START
    }
}

function addAdSuccess() {
    return {
        type: ADD_AD_SUCCESS
    }
}

function addAdError( error ) {
    return {
        type: ADD_AD_ERROR,
        error: error
    }
}

export function addAd( ad ) {
    return dispatch => {
        dispatch( addAdStart() );

        return DataAccess.add( ad )
            .then( () => {
                dispatch( addAdSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка добавления рекламы";
                dispatch( addAdError( error ) );
            } );
    }
}
//#endregion

//#region resetAdsError
export const RESET_ADS_ERROR = "RESET_ADS_ERROR";

function doResetAdsError() {
    return {
        type: RESET_ADS_ERROR
    }
}

export function resetAdsError() {
    return dispatch => {
        dispatch( doResetAdsError() );
    }
}
//#endregion
