import React, { Component } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import IsicTree from '../Cabinet/Isic/IsicTree';
import "./FieldIsicTree.css";
import Octicon, { KebabHorizontal } from '@githubprimer/octicons-react'

class FieldIsicTree extends Component {
    constructor( props ) {
        super( props );

        this.state = { showModal: false };
    }

    render() {
        return (
            <React.Fragment>
                <FormControl
                    {...this.props.input}
                    type="hidden"
                />
                <Button variant="outline-secondary" onClick={() => this.setState( { showModal: true } )}>
                    <Octicon icon={KebabHorizontal} ariaLabel="Редактировать" />
                </Button>

                <Modal
                    open={this.state.showModal}
                    onClose={() => this.setState( { showModal: false } )}
                    classNames={{ modal: 'modal-isic-tree' }}
                    center
                >
                    <IsicTree isicID={this.props.input.value} setIsicID={this.props.setIsicID} />
                </Modal>
            </React.Fragment>
        );
    }
}

export default FieldIsicTree;
