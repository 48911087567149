import React, { Component } from "react";
import PageHeader from "../PageHeader";
import withAuth from "../Auth/withAuth";
import GridWithPager from "./GridWithPager";

class EventsPage extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHeader name="События" />

                <GridWithPager />
            </React.Fragment>
        );
    }
}

export default withAuth( EventsPage );