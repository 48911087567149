import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAreas } from "../../actions/areasActions";

import { DropdownButton, Dropdown, Alert } from "react-bootstrap";
import Spinner from "../Spinner";

import "./AreasDropdown.css";

class AreasDropdown extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { title: "Выберите территорию" };
    }

    componentDidMount() {
        this.props.getAreas_();
    }

    onSelect = ( areaID, areaName ) => {
        this.setState( { title: areaName } );
        this.props.onAreaChange( areaID );
    }

    render() {
        const { isFetching, error, areas } = this.props;

        return (
            <div className="text-align-left">
                {isFetching && areas.length === 0 && <Spinner />}
                {!isFetching && error && <Alert variant="danger">Ошибка загрузки территорий: {error.message}.</Alert>}
                {!isFetching && !error &&
                    <DropdownButton variant="outline-secondary" id="ddbAreas" title={this.state.title}>
                        <Dropdown.Item key="-1" onSelect={() => this.onSelect( -1, "Все территории" )}>Все территории</Dropdown.Item>
                        <Dropdown.Divider />
                        {areas.map( ( area ) =>
                            <Dropdown.Item
                                key={area.id}
                                onSelect={() => this.onSelect( area.id, area.name )}>{area.name}
                            </Dropdown.Item>
                        )}
                    </DropdownButton>}
            </div>
        );
    }
}

AreasDropdown.propTypes = {
    areas: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    } ).isRequired ).isRequired,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string
    } ),
    getAreas_: PropTypes.func.isRequired,
    onAreaChange: PropTypes.func.isRequired,
}

function mapStateToProps( state ) {
    const { areasReducer } = state;
    const { isFetching, error, areas } = areasReducer || { isFetching: true, error: null, areas: [] }

    return {
        isFetching,
        error,
        areas
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        getAreas_: () => {
            dispatch( getAreas() );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( AreasDropdown );
