import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getPressReleases,
    removePressRelease,
    updatePressRelease,
    addPressRelease,
    setPrConfirmed,
    resetPressReleaseError,
    uploadPrFile
} from '../../actions/pressReleasesActions';

import PressReleasesGrid from './PressReleasesGrid';
import Pager from '../Pager';
import Spinner from '../Spinner';
import AuthService from '../Auth/AuthService';
import Loader from 'react-loader-advanced';
import DangerAlert from "../DangerAlert";

class GridWithPager extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            pageNumber: 1,
            itemsPerPage: 10
        };

        this.spinner = <Spinner />;

        this.Auth = new AuthService();
    }

    handlePageChange = ( page ) => {
        this.setState( { pageNumber: page } );
    }

    loadDetails() {
        this.props.getPressReleases_( this.state.pageNumber, this.state.itemsPerPage );
    }

    componentDidMount() {
        this.loadDetails();
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( this.props.isFetching )
            return;

        if( prevProps !== this.props ) {
            //  pageNumber храним в локальном state, но он может из fetch приходить пересчитанный (если запросили бОльшую страницу, чем доступно)
            const { pageNumber } = this.props;
            //console.log(errorDeleting);
            this.setState( { pageNumber } );
        }

        if( prevState.pageNumber !== this.state.pageNumber )
            this.loadDetails();
    }

    onRemovePressRelease = ( id ) => {
        this.props.removePressRelease_( id )
            .then( () => {
                if( !this.props.error )
                    this.loadDetails();
            } )
    }

    onUpdatePressRelease = ( pr ) => {
        this.props.updatePressRelease_( pr )
            .then( () => {
                if( pr.filename instanceof FileList ) {
                    this.props.uploadPrFile_( pr.id, pr.filename[ 0 ] )
                        .then( () => {
                            if( !this.props.error )
                                this.loadDetails();
                        } );
                }
            } )
            .then( () => {
                if( !this.props.error )
                    this.loadDetails();
            } );
    }

    onAddPressRelease = ( pr ) => {
        this.props.addPressRelease_( pr )
            .then( () => {
                if( !this.props.error )
                    this.loadDetails();
            } )
    }

    setConfirmed = ( id, checked ) => {
        this.props.setConfirmed_( id, checked );
    }

    render() {
        const { isFetching, error, totalCount, pressReleases } = this.props;

        return (
            <React.Fragment>
                {error && <p>123</p>}
                <DangerAlert
                    error={error}
                    onClose={() => this.props.resetPressReleaseError_()}
                />

                <Loader show={isFetching} message={this.spinner}>
                    <PressReleasesGrid
                        pressReleases={pressReleases}
                        onRemovePressRelease={this.onRemovePressRelease}
                        onUpdatePressRelease={this.onUpdatePressRelease}
                        onAddPressRelease={this.onAddPressRelease}
                        setConfirmed={this.setConfirmed} />
                </Loader>
                <div className="text-left">
                    <Pager
                        recCount={totalCount}
                        itemsPerPage={this.state.itemsPerPage}
                        pageNumber={this.state.pageNumber}
                        onPageChange={this.handlePageChange}
                        margin={2}
                    />
                </div>
            </React.Fragment> );
    }
}

GridWithPager.propTypes = {
    totalCount: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    pressReleases: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        filename: PropTypes.string,
        date_modified: PropTypes.string,
        can_edit: PropTypes.bool.isRequired,
        is_confirmed: PropTypes.bool.isRequired,
        can_confirm: PropTypes.bool.isRequired,
    } ).isRequired ).isRequired,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( { type: PropTypes.string, name: PropTypes.string, message: PropTypes.string } ),
    newID: PropTypes.number,
    getPressReleases_: PropTypes.func.isRequired,
    removePressRelease_: PropTypes.func.isRequired,
    updatePressRelease_: PropTypes.func.isRequired,
    addPressRelease_: PropTypes.func.isRequired,
    setConfirmed_: PropTypes.func.isRequired,
    resetPressReleaseError_: PropTypes.func.isRequired,
    uploadPrFile_: PropTypes.func.isRequired,
}

function mapStateToProps( state ) {
    const { pressReleasesReducer } = state;

    const {
        totalCount, pageNumber, pressReleases,
        isFetching, error,
        newID } = pressReleasesReducer || {
            totalCount: 0, pageNumber: 1, pressReleases: [],
            isFetching: true, error: null,
            newID: null
        };

    return {
        totalCount, pageNumber, pressReleases,
        isFetching, error,
        newID
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getPressReleases_: ( pageNumber, itemsPerPage ) => {
            dispatch( getPressReleases( pageNumber, itemsPerPage ) );
        },
        removePressRelease_: ( id ) => {
            return dispatch( removePressRelease( id ) );
        },
        updatePressRelease_: ( pr ) => {
            return dispatch( updatePressRelease( pr ) );
        },
        addPressRelease_: ( pr ) => {
            return dispatch( addPressRelease( pr ) );
        },
        setConfirmed_: ( id, checked ) => {
            return dispatch( setPrConfirmed( id, checked ) );
        },
        resetPressReleaseError_: () => {
            return dispatch( resetPressReleaseError() );
        },
        uploadPrFile_: ( id, file ) => {
            return dispatch( uploadPrFile( id, file ) );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( GridWithPager );