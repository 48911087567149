import IsicDataAccess from "../DataAccess/IsicDataAccess";
const DataAccess = new IsicDataAccess();

export const FETCH_ISIC_START = "FETCH_ISIC_START";
export const FETCH_ISIC_SUCCESS = "FETCH_ISIC_SUCCESS";
export const FETCH_ISIC_ERROR = "FETCH_ISIC_ERROR";

function fetchIsicStart() {
    return {
        type: FETCH_ISIC_START
    }
}

function fetchIsicSuccess( treeData ) {
    return {
        type: FETCH_ISIC_SUCCESS,
        treeData: treeData
    }
}

function fetchIsicError( error ) {
    return {
        type: FETCH_ISIC_ERROR,
        error: error
    }
}

export function getIsicData() {
    return dispatch => {
        dispatch( fetchIsicStart() );

        return DataAccess.getIsicData()
            .then( response => response.json() )
            .then( json => dispatch( fetchIsicSuccess( json ) ) )
            .catch( error => { 
                error.name = "Ошибка загрузки дерева отраслей";
                dispatch( fetchIsicError( error ) );
            });
    }
}
