import {
    FETCH_ISIC_START,
    FETCH_ISIC_SUCCESS,
    FETCH_ISIC_ERROR
} from "../actions/isicActions";

export default function isicReducer(
    state = {
        isFetching: false,
        error: null,
        treeData: []
    },
    action ) {
    switch( action.type ) {
        case FETCH_ISIC_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case FETCH_ISIC_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                treeData: action.treeData
            } )
        case FETCH_ISIC_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}