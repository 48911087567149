import React from "react";
import PropTypes from "prop-types";
import { Alert, Button } from "react-bootstrap";

class DangerAlert extends React.Component {
    render() {
        if( !this.props.error )
            return null;

        return (
            <Alert variant="danger">
                {this.props.error.name}: {this.props.error.message}.
                &nbsp;&nbsp;&nbsp;
                <Button onClick={() => this.props.onClose()}>Закрыть</Button>
            </Alert>
        );
    }
}

DangerAlert.propTypes = {
    error: PropTypes.shape( { type: PropTypes.string, name: PropTypes.string, message: PropTypes.string } ),
    onClose: PropTypes.func.isRequired,
}

export default DangerAlert;
