import {
    GET_EMAIL_EXISTS_START,
    GET_EMAIL_EXISTS_SUCCESS,
    GET_EMAIL_EXISTS_ERROR,
    REGISTER_EMAIL_START,
    REGISTER_EMAIL_SUCCESS,
    REGISTER_EMAIL_ERROR,
    CONFIRM_REGISTRATION_START,
    CONFIRM_REGISTRATION_SUCCESS,
    CONFIRM_REGISTRATION_ERROR
} from "../actions/registrationActions";

export default function registrationReducer(
    state = {
        isFetching: false,
        error: null,
        emailExists: null,
        registered: false,
        confirmed: false
    }, action ) {
    switch( action.type ) {
        case GET_EMAIL_EXISTS_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case GET_EMAIL_EXISTS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                emailExists: action.emailExists
            } )
        case GET_EMAIL_EXISTS_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )

        case REGISTER_EMAIL_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case REGISTER_EMAIL_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                registered: true
            } )
        case REGISTER_EMAIL_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )

        case CONFIRM_REGISTRATION_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case CONFIRM_REGISTRATION_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                confirmed: true
            } )
        case CONFIRM_REGISTRATION_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )

        default:
            return state;
    }
}
