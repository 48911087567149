import React, { Component } from "react";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAbout } from "../../actions/aboutActions";
import { Alert } from "react-bootstrap";
import Loader from "react-loader-advanced";
import Spinner from "../Spinner";

class AboutPage extends Component {
    constructor( props ) {
        super( props );

        this.spinner = <Spinner />;
    }

    componentDidMount() {
        this.props.getAbout_();
    }

    render() {
        const { isFetching, error } = this.props;

        return (
            <React.Fragment>
                <PageHeader name="Помощь" />
                <Loader show={isFetching} message={this.spinner}>
                    {error && <Alert variant="danger">
                        {this.props.error.name}: {this.props.error.message}.
                    </Alert>}

                    <div dangerouslySetInnerHTML={{ __html: this.props.html }} />
                </Loader>
            </React.Fragment>
        );
    }
}

AboutPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( { type: PropTypes.string, name: PropTypes.string, message: PropTypes.string } ),
    html: PropTypes.string,
    getAbout_: PropTypes.func.isRequired,
}

function mapStateToProps( state ) {
    const { aboutReducer } = state;

    const {
        isFetching, error, html } = aboutReducer || {
            isFetching: true, error: null, html: null
        };

    return {
        isFetching, error,
        html
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getAbout_: () => {
            dispatch( getAbout() );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( AboutPage );
