import AuthService from "../components/Auth/AuthService";
const Auth = new AuthService();

export class ManageUserIntentionDataAccess {
    fetch = ( pageNumber, itemsPerPage ) => {
        return Auth.get( "/api/ManageUserIntention", { pageNumber: pageNumber, pageSize: itemsPerPage } );
    }

    getUserQuest = ( user_id, intention_id ) => {
        return Auth.get( "/api/ManageUserIntention/" + user_id + "/" + intention_id, {} );
    }

    setConfirmed = ( user_id, intention_id, checked ) => {
        return Auth.post(
            "/api/ManageUserIntention/SetConfirmed",
            { UserID: user_id, IntentionID: intention_id, IsConfirmed: checked }
        );
    }
}