import React from 'react';
import PageHeader from "../../PageHeader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withAuth from '../../Auth/withAuth';
import { getProfile } from '../../../actions/profileActions';
import { Alert } from 'react-bootstrap';
import Spinner from '../../Spinner';
import Loader from 'react-loader-advanced';

class ProfilePage extends React.Component {
    constructor( props ) {
        super( props );

        this.spinner = <Spinner />;
    }

    componentDidMount() {
        this.props.getProfile_();
    }

    render() {
        let profile = this.props.profile;

        const { isFetching, error } = this.props;

        return (
            <Loader show={isFetching} message={this.spinner}>
                <PageHeader name="Профиль" />

                {error && <Alert variant="danger">
                    {this.props.error.name}: {this.props.error.message}.
                </Alert>}

                {profile && <React.Fragment>
                    <b>Регистрационный номер:</b> {profile.regNumber}<br />
                    <b>Дата окончания поддержки:</b> {profile.subscriptionEnd}
                </React.Fragment>}
            </Loader>
        );
    }
}

ProfilePage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string
    } ),
    profile: PropTypes.shape( {
        regNumber: PropTypes.string,
        subscriptionEnd: PropTypes.string,
    } ),
    getProfile_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { profileReducer } = state;

    const { isFetching, error, profile } =
        profileReducer ||
        { isFetching: true, error: null, profile: null };

    return { isFetching, error, profile };
}

function mapDispatchToProps( dispatch ) {
    return {
        getProfile_: () => {
            dispatch( getProfile() );
        },
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( ProfilePage ) );
