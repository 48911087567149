import {
    GET_PROFILE_START,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,
} from "../actions/profileActions";

export default function profileReducer(
    state = {
        isFetching: false,
        error: null,
        profile: null,
    }, action ) {
    switch( action.type ) {
        case GET_PROFILE_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case GET_PROFILE_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                profile: action.profile
            } )
        case GET_PROFILE_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
