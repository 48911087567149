import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button } from "react-bootstrap";
import Modal from "react-responsive-modal";
import { getAreas } from "../../actions/areasActions";
import FieldCheckbox from "./FieldCheckbox";
import FieldIsicTree from "./FieldIsicTree";
import "./ModalEditEvent.css";
import PropTypes from "prop-types";
import Octicon, { Alert } from "@githubprimer/octicons-react";

class ModalEditEvent extends Component {
    componentDidMount() {
        this.props.getAreas_();
    }

    renderField = ( {
        input,
        label,
        type,
        textarea,
        meta: { touched, error, warning, invalid },
    } ) => {
        const textareaType = (
            <textarea
                {...input}
                placeholder={label}
                type={type}
                className={`form-control ${
                    touched && invalid ? "invalid" : ""
                    }`}
            />
        );
        const inputType = (
            <input
                {...input}
                placeholder={label}
                type={type}
                className={`form-control ${
                    touched && invalid ? "invalid" : ""
                    }`}
            />
        );

        return (
            <div className="table-row">
                <label className="table-cell">{label}</label>
                &nbsp;&nbsp;&nbsp;
                <div
                    className="table-cell-100"
                    style={{
                        display: "table-cell",
                        padding: "2px",
                        width: "100%",
                    }}
                >
                    {touched &&
                        ( ( error && (
                            <span className="error">
                                <Octicon icon={Alert} ariaLabel="Ошибка" />
                                <i class="fas fa-igloo"></i>
                                &nbsp;{error}
                            </span>
                        ) ) ||
                            ( warning && <span>{warning}</span> ) )}
                    {textarea ? textareaType : inputType}
                </div>
            </div>
        );
    };

    renderIsicNameField = ( {
        input,
        meta: { touched, error, warning, invalid },
    } ) => {
        const inputType = (
            <input
                {...input}
                className={`form-control ${
                    touched && invalid ? "invalid" : ""
                    }`}
                type="text"
                readOnly
                style={{ width: "535px" }}
            />
        );
        return (
            <React.Fragment>
                {touched &&
                    ( ( error && (
                        <span className="error">
                            <Octicon icon={Alert} ariaLabel="Ошибка" />
                            <i class="fas fa-igloo"></i>
                            &nbsp;{error}
                        </span>
                    ) ) ||
                        ( warning && <span>{warning}</span> ) )}
                {inputType}
            </React.Fragment>
        );
    };

    handleClose = () => {
        this.props.onMyHide();
    };

    setIsicID = ( checked, isicID, isicTitle ) => {
        this.props.change( "isic_id", checked ? isicID : "" );
        this.props.change( "isic_name", checked ? isicTitle : "" );
    };

    render() {
        if( !this.props.show ) return null;

        const { handleSubmit, pristine, reset, submitting, areas } = this.props;

        return (
            <Modal
                open={this.props.show}
                onClose={this.handleClose}
                classNames={{ modal: "modal-edit-event" }}
                center
            >
                <form onSubmit={handleSubmit}>
                    <div
                        style={{
                            display: "table",
                            width: "100%",
                            borderSpacing: "2px",
                            marginTop: "25px",
                            marginBottom: "25px",
                        }}
                    >
                        <div className="table-row">
                            <label className="table-cell">Дата</label>
                            &nbsp;&nbsp;&nbsp;
                            <p
                                style={{
                                    display: "table-cell",
                                    padding: "2px",
                                    width: "100%",
                                }}
                            >
                                {this.props.event.date_created}
                            </p>
                        </div>

                        <div className="table-row">
                            <label className="table-cell">Отрасль</label>
                            &nbsp;&nbsp;&nbsp;
                            <div
                                className="form-inline"
                                style={{
                                    display: "table-cell",
                                    padding: "2px",
                                    width: "100%",
                                }}
                            >
                                <Field
                                    name="isic_name"
                                    component={this.renderIsicNameField}
                                />
                                <Field
                                    name="isic_id"
                                    component={FieldIsicTree}
                                    setIsicID={this.setIsicID}
                                />
                            </div>
                        </div>

                        <div className="table-row">
                            <label className="table-cell">Место</label>
                            &nbsp;&nbsp;&nbsp;
                            <div
                                style={{
                                    display: "table-cell",
                                    padding: "2px",
                                    width: "100%",
                                }}
                            >
                                <Field
                                    name="area_id"
                                    component="select"
                                    className="form-control"
                                >
                                    {areas.map( area => (
                                        <option key={area.id} value={area.id}>
                                            {area.name}
                                        </option>
                                    ) )}
                                </Field>
                            </div>
                        </div>

                        <Field
                            name="place_name"
                            component={this.renderField}
                            type="text"
                            label="Название&nbsp;места"
                        />
                        <Field
                            name="address"
                            component={this.renderField}
                            type="text"
                            label="Адрес"
                        />
                        <Field
                            name="latitude"
                            component={this.renderField}
                            type="text"
                            label="Широта"
                        />
                        <Field
                            name="longitude"
                            component={this.renderField}
                            type="text"
                            label="Долгота"
                        />
                        <Field
                            name="objects"
                            component={this.renderField}
                            type="text"
                            label="Объект"
                        />
                        <Field
                            name="purpose"
                            component={this.renderField}
                            type="text"
                            label="Назначение"
                        />
                        <Field
                            name="description"
                            component={this.renderField}
                            type="text"
                            label="Описание"
                            textarea={true}
                        />

                        <div className="table-row">
                            <label className="table-cell">Достоверность</label>
                            &nbsp;&nbsp;&nbsp;
                            <div
                                style={{
                                    display: "table-cell",
                                    padding: "2px",
                                    width: "100%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <Field
                                    name="is_reliable"
                                    component={FieldCheckbox}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr />
                        <span className="floatLeft">
                            <Button type="button" disabled={pristine || submitting} onClick={reset}>Сброс</Button>
                        </span>
                        <span className="floatRight">
                            <Button type="submit" variant="primary" disabled={pristine || submitting}>Сохранить</Button>
                            &nbsp;&nbsp;
                            <Button onClick={this.handleClose}>Отмена</Button>
                        </span>
                    </div>
                </form>
            </Modal>
        );
    }
}

ModalEditEvent.propTypes = {
    areas: PropTypes.arrayOf(
        PropTypes.shape( {
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        } ).isRequired
    ),

    event: PropTypes.shape( {
        address: PropTypes.string.isRequired,
        area_id: PropTypes.number.isRequired,
        area_name: PropTypes.string.isRequired,
        date_created: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        is_reliable: PropTypes.bool.isRequired,
        isic_id: PropTypes.number,
        isic_name: PropTypes.string.isRequired,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        objects: PropTypes.string.isRequired,
        place_name: PropTypes.string.isRequired,
        purpose: PropTypes.string,
    } ),

    onMyHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,

    getAreas_: PropTypes.func.isRequired,
};

function mapStateToProps( state, ownProps ) {
    if( !ownProps.event ) return {};

    const { areasReducer } = state;
    const { areas } = areasReducer || { areas: [] };

    return {
        areas,
        initialValues: { ...ownProps.event },
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getAreas_: () => {
            dispatch( getAreas() );
        },
    };
}

const validate = values => {
    const errors = {};

    if( !values.isic_name ) errors.isic_name = "Выберите отрасль";

    if( !values.address ) errors.address = "Введите адрес";
    else if( values.address.length > 100 )
        errors.address = "Не более 100 символов";

    if( !values.latitude ) errors.latitude = "Введите широту";
    else if( isNaN( values.latitude ) )
        errors.latitude = "Значение должно быть численным";
    else if( values.latitude < -90 )
        errors.latitude = "Значение должно быть больше -90";
    else if( values.latitude > 90 )
        errors.latitude = "Значение должно быть меньше 90";

    if( !values.longitude ) errors.longitude = "Введите долготу";
    else if( isNaN( values.longitude ) )
        errors.longitude = "Значение должно быть численным";
    else if( values.longitude < -180 )
        errors.longitude = "Значение должно быть больше -180";
    else if( values.longitude > 180 )
        errors.longitude = "Значение должно быть меньше 180";

    if( !values.objects ) errors.objects = "Введите объект";
    else if( values.objects.length > 40 )
        errors.objects = "Не более 40 символов";

    if( !values.appointment ) errors.appointment = "Введите назначение";
    else if( values.appointment.length > 40 )
        errors.appointment = "Не более 40 символов";

    if( !values.description ) errors.description = "Введите описание";

    return errors;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm( {
        form: "modalEditEvent",
        enableReinitialize: true,
        validate,
    } )( ModalEditEvent )
);
