import React from "react";
import { Table, Alert, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import PropTypes from "prop-types";
import Octicon, { CloudDownload } from "@githubprimer/octicons-react";

class Grid extends React.Component {
    setConfirmed( user_id, intention_id, event ) {
        this.props.setConfirmed( user_id, intention_id, event.target.checked );
    }

    render() {
        let intentions = this.props.intentions;

        if( !intentions )
            return (
                <Alert variant="info"><strong>Сервер не вернул ни одного намерения</strong></Alert>
            );

        let rows = intentions.map( ( i, index ) =>
            <tr key={index}>
                <td align="left">{i.registration_date}</td>
                <td align="left">{i.email}</td>
                <td align="left">{i.intention_name}</td>
                <td align="center">
                    {i.quest_exists &&
                        <Button variant="outline-secondary" size="sm" onClick={() => this.props.getUserQuest( i.user_id, i.intention_id, i.email, i.intention_name )}>
                            <Octicon icon={CloudDownload} ariaLabel="Скачать" />
                        </Button>}
                </td>
                <td align="center">
                    <Form.Check
                        type="checkbox"
                        checked={i.is_confirmed}
                        onChange={( e ) => this.setConfirmed( i.user_id, i.intention_id, e )}
                    />
                </td>
            </tr>
        );

        return (
            <Table striped bordered condensed="true" hover size="sm">
                <thead>
                    <tr>
                        <th>Дата регистрации</th>
                        <th>e-mail</th>
                        <th>Намерение</th>
                        <th>Анкета</th>
                        <th>Одобрено</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        );
    }
}

Grid.propTypes = {
    getUserQuest: PropTypes.func.isRequired,
    setConfirmed: PropTypes.func.isRequired,
}

export default Grid;