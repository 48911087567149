import React, { Component } from "react";
import PageHeader from "../PageHeader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import Spinner from "../Spinner";
import Loader from "react-loader-advanced";
import { confirmRegistration } from "../../actions/registrationActions";

import AuthService from "../Auth/AuthService";

class ConfirmRegistrationPage extends Component {
    constructor( props ) {
        super( props );

        this.spinner = <Spinner />;

        this.Auth = new AuthService();
    }

    componentDidMount() {
        if( this.Auth.loggedIn() )
            this.props.history.replace( '/' );

        const queryString = require( 'query-string' );

        const parsed = queryString.parse( this.props.location.search );

        const guid = parsed[ "guid" ];

        this.props.confirmRegistration_( guid );
    }

    render() {
        const { isFetching, error, confirmed } = this.props;

        return (
            <React.Fragment>
                <PageHeader name="Подтверждение регистрации" />

                <Loader show={isFetching} message={this.spinner}>
                    {error && <Alert variant="danger">Ошибка подтверждения регистрации: {error.message}.</Alert>}
                    {confirmed && <Alert variant="success">Регистрация подтверждена. Теперь Вы можете войти в систему с Вашим email и паролем.</Alert>}
                </Loader>
            </React.Fragment>
        );
    }
}

ConfirmRegistrationPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string
    } ),
    confirmed: PropTypes.bool,
    confirmRegistration_: PropTypes.func.isRequired
}

function mapStateToProps( state ) {
    const { registrationReducer } = state;

    const
        { isFetching, error, confirmed } =
            registrationReducer ||
            { isFetching: false, error: null, confirmed: true }

    return { isFetching, error, confirmed };
}

function mapDispatchToProps( dispatch ) {
    return {
        confirmRegistration_: ( guid ) => {
            return dispatch( confirmRegistration( guid ) );  //  !!! обязательно return, чтобы возвращался Promise
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( ConfirmRegistrationPage );