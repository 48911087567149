import React, { Component } from "react";
import PageHeader from "../PageHeader";
import { Button, FormControl, FormLabel, Form, FormGroup } from "react-bootstrap";
import AuthService from './AuthService';
import AlertEntryError from './AlertEntryError';

class Login extends Component {
    constructor( props ) {
        super( props );

        this.state = { email: "", password: "", showAlert: false };

        this.Auth = new AuthService();
    }

    componentWillMount() {
        if( this.Auth.loggedIn() )
            this.props.history.goBack();
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState( {
            [ event.target.id ]: event.target.value
        } );
    }

    handleSubmit = event => {
        event.preventDefault();

        this.Auth.login( this.state.email, this.state.password )
            .then( res => {
                //this.props.history.replace('/Cabinet');
                this.props.history.goBack();
            } )
            .catch( err => {
                this.setState( { showAlert: true } );
            } )
    }

    render() {
        let doCloseAlert = () => this.setState( { showAlert: false } );

        return (
            <React.Fragment>
                <PageHeader name="Вход в систему" />

                <div className="Login">
                    <AlertEntryError show={this.state.showAlert} onMyHide={doCloseAlert} />
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup controlId="email" size="lg">
                            <FormLabel>e-mail</FormLabel>
                            <FormControl
                                autoFocus
                                type="email"
                                value={this.state.email}
                                onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup controlId="password" size="lg">
                            <FormLabel>Пароль</FormLabel>
                            <FormControl
                                value={this.state.password}
                                onChange={this.handleChange}
                                type="password" />
                        </FormGroup>
                        <Button
                            block
                            size="lg"
                            disabled={!this.validateForm()}
                            type="submit">
                            Войти
                    </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;