import React, { Component } from "react";
import PageHeader from "../PageHeader";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Alert } from "react-bootstrap";
import Register from "./Register";
import { SubmissionError } from "redux-form";
import Spinner from "../Spinner";
import {
    getEmailexists,
    registerEmail,
} from "../../actions/registrationActions";
import Loader from "react-loader-advanced";

class RegistrationPage extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            user: { email: "", password: "", confirmPassword: "" },
        };

        this.spinner = <Spinner />;
    }

    onRegister = values => {
        this.props.getEmailexists_( values.email ).then( () => {
            if( this.props.emailExists )
                throw new SubmissionError( {
                    email: "email уже существует",
                    _error: "Ошибка регистрации",
                } );
            else {
                this.props.registerEmail_( values.email, values.password );
            }
        } );
    };

    render() {
        const { isFetching, error, registered, emailExists } = this.props;

        return (
            <React.Fragment>
                <PageHeader name="Регистрация" />
                <Loader show={isFetching} message={this.spinner}>
                    <div className="Login">
                        {emailExists && (
                            <Alert variant="danger">
                                Пользователь с этим e-mail уже существует.
                            </Alert>
                        )}

                        {error && (
                            <Alert variant="danger">
                                Ошибка проверки существования пользователя:{" "}
                                {error.message}.
                            </Alert>
                        )}
                        {registered ? (
                            <Alert variant="success">
                                Ссылка для подтверждения регистрации отправлена
                                Вам на e-mail
                            </Alert>
                        ) : (
                                <Register
                                    user={this.state.user}
                                    onSubmit={this.onRegister}
                                />
                            )}
                    </div>
                </Loader>
            </React.Fragment>
        );
    }
}

RegistrationPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string,
    } ),
    emailExists: PropTypes.bool,
    registered: PropTypes.bool.isRequired,
    getEmailexists_: PropTypes.func.isRequired,
    registerEmail_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { registrationReducer } = state;

    const {
        isFetching,
        error,
        emailExists,
        registered,
    } = registrationReducer || {
        isFetching: true,
        error: null,
        emailExists: null,
        registered: false,
    };

    return { isFetching, error, emailExists, registered };
}

function mapDispatchToProps( dispatch ) {
    return {
        getEmailexists_: email => {
            return dispatch( getEmailexists( email ) ); //  !!! обязательно return, чтобы возвращался Promise
        },
        registerEmail_: ( email, password ) => {
            return dispatch( registerEmail( email, password ) );
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( RegistrationPage );
