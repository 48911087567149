import React from 'react';
import { PulseLoader } from 'react-spinners';

class Spinner extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            loading: true
        }
    }

    render() {
        return (
            <span className='sweet-loading'>
                <PulseLoader
                    color={'#9B9B9B'}
                    loading={this.state.loading}
                />
            </span>
        )
    }
}

export default Spinner;
