import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getEvents,
    removeEvent,
    updateEvent,
    addEvent,
    resetEventsError,
} from "../../actions/eventsActions";

import EventsGrid from "./EventsGrid";
import Pager from "../Pager";
import Spinner from "../Spinner";
import AuthService from "../Auth/AuthService";
import Loader from "react-loader-advanced";
import DangerAlert from "../DangerAlert";

class GridWithPager extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            pageNumber: 1,
            itemsPerPage: 10,
        };

        this.Auth = new AuthService();
        this.spinner = <Spinner />;
    }

    handlePageChange = page => {
        this.setState( { pageNumber: page } );
    };

    loadDetails() {
        this.props.getEvents_( this.state.pageNumber, this.state.itemsPerPage );
    }

    componentDidMount() {
        this.loadDetails();
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( this.props.isFetching ) return;

        if( prevProps !== this.props ) {
            //  pageNumber храним в локальном state, но он может из fetch приходить пересчитанный (если запросили бОльшую страницу, чем доступно)
            const { pageNumber } = this.props;
            this.setState( { pageNumber } );
        }

        if( prevState.pageNumber !== this.state.pageNumber ) this.loadDetails();
    }

    onRemoveEvent = eventID => {
        this.props
            .removeEvent_( eventID )
            .then( () => {
                if( !this.props.error ) this.loadDetails();
            } )
            .catch( () => {
                console.log( "Ошибка удаления" );
            } );
    };

    onUpdateEvent = event => {
        this.props.updateEvent_( event );
        /* редукс сохранит изменения в state, перегружать с сервера не надо
            .then( () => {
                this.loadDetails();
            })*/
        /*.catch( () => { console.log( "Ошибка" ); } );*/
    };

    onAddEvent = event => {
        this.props.addEvent_( event ).then( () => {
            if( !this.props.error ) this.loadDetails();
        } );
        /*.catch( () => { console.log( "Ошибка" ); } );*/
    };

    handleDismissDeleting = () => {
        this.setState( { errorDeleting: null } );
    };

    handleDismissUpdating = () => {
        this.setState( { errorUpdating: null } );
    };

    handleDismissAdding = () => {
        this.setState( { errorAdding: null } );
    };

    render() {
        const { isFetching, error, events, totalCount } = this.props;

        /*if( errorFetching )
            return <Alert variant="danger">Ошибка загрузки событий: {errorFetching.message}.</Alert>;*/

        return (
            <React.Fragment>
                <DangerAlert
                    error={error}
                    onClose={() => this.props.resetEventsError_()}
                />
                {/*{this.state.errorDeleting &&
                    <Alert variant="danger">
                        Ошибка удаления события: {this.state.errorDeleting.message}.
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={ this.handleDismissDeleting }>Закрыть</Button>
                    </Alert>
                }
                {this.state.errorUpdating &&
                    <Alert variant="danger">
                        Ошибка обновления события: {this.state.errorUpdating.message}.
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={ this.handleDismissUpdating }>Закрыть</Button>
                    </Alert>
                }
                {this.state.errorAdding &&
                    <Alert variant="danger">
                        Ошибка добавления события: {this.state.errorAdding.message}.
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={ this.handleDismissAdding }>Закрыть</Button>
                    </Alert>
                }*/}
                <Loader show={isFetching} message={this.spinner}>
                    <EventsGrid
                        events={events}
                        onRemoveEvent={this.onRemoveEvent}
                        onUpdateEvent={this.onUpdateEvent}
                        onAddEvent={this.onAddEvent}
                    />
                </Loader>
                <div className="text-left">
                    <Pager
                        recCount={totalCount}
                        itemsPerPage={this.state.itemsPerPage}
                        pageNumber={this.state.pageNumber}
                        onPageChange={this.handlePageChange}
                        margin={2}
                    />
                </div>
            </React.Fragment>
        );
    }
}

GridWithPager.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape( {
            id: PropTypes.number.isRequired,
            date_created: PropTypes.string.isRequired,
            description: PropTypes.string,
            area_name: PropTypes.string.isRequired,
            is_reliable: PropTypes.bool.isRequired,
            objects: PropTypes.string,
            place_name: PropTypes.string.isRequired,
            latitude: PropTypes.number,
            longitude: PropTypes.number,
            can_edit: PropTypes.bool.isRequired,
        } ).isRequired
    ),
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string,
    } ),
    getEvents_: PropTypes.func.isRequired,
    removeEvent_: PropTypes.func.isRequired,
    updateEvent_: PropTypes.func.isRequired,
    addEvent_: PropTypes.func.isRequired,
    resetEventsError_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { eventsReducer } = state;

    const {
        totalCount,
        pageNumber,
        events,
        isFetching,
        error,
    } = eventsReducer || {
        totalCount: 0,
        pageNumber: 1,
        events: [],
        isFetching: true,
        error: null,
    };

    return {
        totalCount,
        pageNumber,
        events,
        isFetching,
        error,
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getEvents_: ( pageNumber, itemsPerPage ) => {
            dispatch( getEvents( pageNumber, itemsPerPage ) );
        },
        removeEvent_: eventID => {
            return dispatch( removeEvent( eventID ) );
        },
        updateEvent_: event => {
            return dispatch( updateEvent( event ) );
        },
        addEvent_: event => {
            return dispatch( addEvent( event ) );
        },
        resetEventsError_: () => {
            return dispatch( resetEventsError() );
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( GridWithPager );
