import React, { Component } from "react";
import { FormControl, Button } from "react-bootstrap";
import Octicon, { Search, X } from "@githubprimer/octicons-react";

class SearchBar extends Component {
    constructor( props ) {
        super( props );

        this.state = { search: "" };
    }

    onSearchChange = event => {
        this.setState( { search: event.target.value } );
    };

    onSearchKeyPress = event => {
        if( event.charCode === 13 )
            this.props.onSearchChange( this.state.search );
    };

    clearSearch = () => {
        this.setState( { search: "" } );
        this.props.onSearchChange( "" );
    };

    doSearch = () => {
        this.props.onSearchChange( this.state.search );
    };

    render() {
        return (
            <div className="d-inline-flex">
                <FormControl
                    type="text"
                    value={this.state.search}
                    placeholder="Поиск по описанию"
                    onChange={this.onSearchChange}
                    onKeyPress={this.onSearchKeyPress}
                />
                <Button variant="outline-secondary" onClick={this.clearSearch}>
                    <Octicon icon={X} ariaLabel="Очистить" />
                </Button>
                <Button variant="outline-secondary" onClick={this.doSearch}>
                    <Octicon icon={Search} ariaLabel="Искать" />
                </Button>
            </div>
        );
    }
}

export default SearchBar;
