import {
    GET_MANAGE_USER_INTENTIONS_START,
    GET_MANAGE_USER_INTENTIONS_SUCCESS,
    GET_MANAGE_USER_INTENTIONS_ERROR,
    RESET_MANAGE_USER_INTENTIONS_ERROR,
    SET_UI_CONFIRMED_START,
    SET_UI_CONFIRMED_SUCCESS,
    SET_UI_CONFIRMED_ERROR,
} from "../actions/manageUserIntentionActions";

export default function eventsReducer(
    state = {
        isFetching: false,
        error: null,
        pageNumber: 1,
        totalCount: 0,
        intentions: [],
    }, action ) {
    switch( action.type ) {
        case GET_MANAGE_USER_INTENTIONS_START:
        case SET_UI_CONFIRMED_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case GET_MANAGE_USER_INTENTIONS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                pageNumber: action.pageNumber,
                totalCount: action.totalCount,
                intentions: action.intentions
            } )
        case GET_MANAGE_USER_INTENTIONS_ERROR:
        case SET_UI_CONFIRMED_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        case RESET_MANAGE_USER_INTENTIONS_ERROR:
            return Object.assign( {}, state, {
                error: null
            } )
        case SET_UI_CONFIRMED_SUCCESS:
            var intentions = state.intentions.map( i => {
                if( ( i.user_id === action.user_id ) && ( i.intention_id === action.intention_id ) ) {
                    return {
                        ...i,
                        is_confirmed: action.checked
                    }
                } else return i;
            } );

            return Object.assign( {}, state, {
                isFetching: false,
                intentions
            } )
        default:
            return state;
    }
}
