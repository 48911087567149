import React, { Component } from 'react';
import PageHeader from "../PageHeader";
import withAuth from '../Auth/withAuth';
import GridWithPager from "./GridWithPager";

class ManageUserIntentionPage extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHeader name="Управление намерениями пользователя" />

                <GridWithPager />
            </React.Fragment>
        );
    }
}

export default withAuth( ManageUserIntentionPage );