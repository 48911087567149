import {
    FETCH_AREAS_START,
    FETCH_AREAS_SUCCESS,
    FETCH_AREAS_ERROR
} from "../actions/areasActions";

export default function areasReducer(
    state = {
        isFetching: false,
        error: null,
        areas: []
    }, action ) {
    switch( action.type ) {
        case FETCH_AREAS_START:
            return Object.assign( {}, state, {
                isFetching: true
            } )
        case FETCH_AREAS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                areas: action.areas
            } )
        case FETCH_AREAS_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
