import React from 'react';
import PageHeader from "../../PageHeader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withAuth from '../../Auth/withAuth';

import IsicTree from './IsicTree';
import SubscriptionCheckbox from './SubscriptionCheckbox';

import { getIsicID, setIsicID } from '../../../actions/subscriptionsActions';

class IsicPage extends React.Component {
    constructor( props ) {
        super( props );

        this.state = { cbEnabled: false };
    }

    componentDidMount() {
        this.props.getIsicID_();
    }

    render() {
        const { isFetching, error } = this.props;

        return (
            <React.Fragment>
                <PageHeader name="Отрасль пользователя" />

                <SubscriptionCheckbox cbEnabled={this.props.isicID != null && !isNaN( this.props.isicID )} />
                <IsicTree
                    isicID={this.props.isicID}
                    setIsicID={this.setIsicID}
                    isFetchingIsicID={isFetching}
                    error={error}
                />
            </React.Fragment>
        );
    }

    setIsicID = ( checked, isicID, isicTitle ) => {
        /*Promise.resolve( this.props.setIsicID_( checked ? isicID : null ) )
            .then( () => setTimeout( this.props.getIsicID_(), 500 ) );*/
        this.props.setIsicID_( checked ? isicID : null );
    }
}

IsicPage.propTypes = {
    getIsicID_: PropTypes.func.isRequired,
    setIsicID_: PropTypes.func.isRequired,
    isicID: PropTypes.number,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string
    } )
};

function mapStateToProps( state ) {
    const { subscriptionsReducer } = state;
    const { isFetching, error, isicID } = subscriptionsReducer || { isFetching: true, error: null, isicID: null };
    return { isFetching, error, isicID };
}

function mapDispatchToProps( dispatch ) {
    return {
        getIsicID_: () => {
            dispatch( getIsicID() );
        },
        setIsicID_: isicID => {
            dispatch( setIsicID( isicID ) );
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( withAuth( IsicPage ) );
