import { PressReleasesDataAccess } from "../DataAccess/PressReleasesDataAccess";
const DataAccess = new PressReleasesDataAccess();

//#region getPressReleases
export const GET_PRESSRELEASES_START = "GET_PRESSRELEASES_START";
export const GET_PRESSRELEASES_SUCCESS = "GET_PRESSRELEASES_SUCCESS";
export const GET_PRESSRELEASES_ERROR = "GET_PRESSRELEASES_ERROR";

function getPressReleasesStart( pageNumber, itemsPerPage ) {
    return {
        type: GET_PRESSRELEASES_START,
        pageNumber,
        itemsPerPage
    }
}

function getPressReleasesSuccess( pageNumber, totalCount, pressreleases ) {
    return {
        type: GET_PRESSRELEASES_SUCCESS,
        pageNumber,
        totalCount,
        pressReleases: pressreleases
    }
}

function getPressReleasesError( error ) {
    return {
        type: GET_PRESSRELEASES_ERROR,
        error: error
    }
}

export function getPressReleases( pageNumber, itemsPerPage ) {
    return dispatch => {
        dispatch( getPressReleasesStart( pageNumber, itemsPerPage ) );

        return DataAccess.fetch( pageNumber, itemsPerPage )
            .then( response => response.json() )
            .then( json => dispatch( getPressReleasesSuccess( json.page_number, json.total_count, json.pressreleases ) ) )
            /*response.json().then( json => {
                dispatch( getPressReleasesSuccess( json.page_number, json.total_count, json.pressreleases ) );
            });*/
            .catch( error => {
                error.name = "Ошибка загрузки пресс-релизов";
                dispatch( getPressReleasesError( error ) );
            } );
    }
}
//#endregion

//#region updatePressRelease
export const UPDATE_PRESS_RELEASE_START = "UPDATE_PRESS_RELEASE_START";
export const UPDATE_PRESS_RELEASE_SUCCESS = "UPDATE_PRESS_RELEASE_SUCCESS";
export const UPDATE_PRESS_RELEASE_ERROR = "UPDATE_PRESS_RELEASE_ERROR";

function updatePressReleaseStart() {
    return {
        type: UPDATE_PRESS_RELEASE_START
    }
}

function updatePressReleaseSuccess() {
    return {
        type: UPDATE_PRESS_RELEASE_SUCCESS
    }
}

function updatePressReleaseError( error ) {
    return {
        type: UPDATE_PRESS_RELEASE_ERROR,
        error: error
    }
}

export function updatePressRelease( pressRelease ) {
    return dispatch => {
        dispatch( updatePressReleaseStart() );

        return DataAccess.update( pressRelease )
            .then( () => {
                dispatch( updatePressReleaseSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка обновления пресс-релиза";
                dispatch( updatePressReleaseError( error ) );
            } );
    }
}
//#endregion

//#region addPressRelease
export const ADD_PRESS_RELEASE_START = "ADD_PRESS_RELEASE_START";
export const ADD_PRESS_RELEASE_SUCCESS = "ADD_PRESS_RELEASE_SUCCESS";
export const ADD_PRESS_RELEASE_ERROR = "ADD_PRESS_RELEASE_ERROR";

function addPressReleaseStart() {
    return {
        type: ADD_PRESS_RELEASE_START
    }
}

function addPressReleaseSuccess( newID ) {
    return {
        type: ADD_PRESS_RELEASE_SUCCESS,
        newID: parseInt( newID, 10 )
    }
}

function addPressReleaseError( error ) {
    return {
        type: ADD_PRESS_RELEASE_ERROR,
        error: error
    }
}

export function addPressRelease( pr ) {
    return dispatch => {
        dispatch( addPressReleaseStart() );

        return DataAccess.add( pr )
            /*.then( response => {
                response.text().then( t => {
                    dispatch( addPressReleaseSuccess( t ) );
                });
            })*/
            .then( response => response.text() )
            .then( text => dispatch( addPressReleaseSuccess( text ) ) )
            .catch( error => {
                error.name = "Ошибка добавления пресс-релиза";
                dispatch( addPressReleaseError( error ) );
            } );
    }
}
//#endregion

//#region getPressReleaseFile
export const GET_PRESS_RELEASE_FILE_START = "GET_PRESS_RELEASE_FILE_START";
export const GET_PRESS_RELEASE_FILE_SUCCESS = "GET_PRESS_RELEASE_FILE_SUCCESS";
export const GET_PRESS_RELEASE_FILE_ERROR = "GET_PRESS_RELEASE_FILE_ERROR";

function getPressReleaseFileStart( filename ) {
    return {
        type: GET_PRESS_RELEASE_FILE_START,
        filename
    }
}

function getPressReleaseFileSuccess( html ) {
    return {
        type: GET_PRESS_RELEASE_FILE_SUCCESS,
        html
    }
}

function getPressReleaseFileError( error ) {
    return {
        type: GET_PRESS_RELEASE_FILE_ERROR,
        error: error
    }
}

export function getPressReleaseFile( filename ) {
    return dispatch => {
        dispatch( getPressReleaseFileStart( filename ) );

        return DataAccess.getFile( filename )
            .then( response => response.text() )
            .then( text => dispatch( getPressReleaseFileSuccess( text ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки файла пресс-релиза";
                dispatch( getPressReleaseFileError( error ) );
            } );
    }
}
//#endregion

//#region deletePressRelease
export const DELETE_PRESS_RELEASE_START = "DELETE_PRESS_RELEASE_START";
export const DELETE_PRESS_RELEASE_SUCCESS = "DELETE_PRESS_RELEASE_SUCCESS";
export const DELETE_PRESS_RELEASE_ERROR = "DELETE_PRESS_RELEASE_ERROR";

function deletePressReleaseStart() {
    return {
        type: DELETE_PRESS_RELEASE_START
    }
}

function deletePressReleaseSuccess() {
    return {
        type: DELETE_PRESS_RELEASE_SUCCESS
    }
}

function deletePressReleaseError( error ) {
    return {
        type: DELETE_PRESS_RELEASE_ERROR,
        error: error
    }
}

export function removePressRelease( id ) {
    return dispatch => {
        dispatch( deletePressReleaseStart() );

        return DataAccess.remove( id )
            .then( () => {
                dispatch( deletePressReleaseSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка удаления пресс-релиза";
                dispatch( deletePressReleaseError( error ) );
                throw error;
            } );
    }
}
//#endregion

//#region setPrConfirmed
export const SET_PR_CONFIRMED_START = "SET_PR_CONFIRMED_START";
export const SET_PR_CONFIRMED_SUCCESS = "SET_PR_CONFIRMED_SUCCESS";
export const SET_PR_CONFIRMED_ERROR = "SET_PR_CONFIRMED_ERROR";

function setPrConfirmedStart() {
    return {
        type: SET_PR_CONFIRMED_START
    }
}

function setPrConfirmedSuccess( id, checked ) {
    return {
        type: SET_PR_CONFIRMED_SUCCESS,
        id,
        checked
    }
}

function setPrConfirmedError( error ) {
    return {
        type: SET_PR_CONFIRMED_ERROR,
        error: error
    }
}

export function setPrConfirmed( id, checked ) {
    return dispatch => {
        dispatch( setPrConfirmedStart() );

        return DataAccess.setConfirmed( id, checked )
            .then( () => {
                dispatch( setPrConfirmedSuccess( id, checked ) );
            } )
            .catch( error => {
                error.name = "Ошибка подтверждения пресс-релиза";
                dispatch( setPrConfirmedError( error ) );
            } );
    }
}
//#endregion

//#region resetPressReleaseError
export const RESET_PRESS_RELEASE_ERROR = "RESET_PRESS_RELEASE_ERROR";

function doResetPressReleaseError() {
    return {
        type: RESET_PRESS_RELEASE_ERROR
    }
}

export function resetPressReleaseError() {
    return dispatch => {
        dispatch( doResetPressReleaseError() );
    }
}
//#endregion

//#region uploadPrFile
export const UPLOAD_PR_FILE_START = "UPLOAD_PR_FILE_START";
export const UPLOAD_PR_FILE_SUCCESS = "UPLOAD_PR_FILE_SUCCESS";
export const UPLOAD_PR_FILE_ERROR = "UPLOAD_PR_FILE_ERROR";

function uploadPrFileStart() {
    return {
        type: UPLOAD_PR_FILE_START
    }
}

function uploadPrFileSuccess() {
    return {
        type: UPLOAD_PR_FILE_SUCCESS
    }
}

function uploadPrFileError( error ) {
    return {
        type: UPLOAD_PR_FILE_ERROR,
        error: error
    }
}

export function uploadPrFile( id, file ) {
    return dispatch => {
        dispatch( uploadPrFileStart() );

        return DataAccess.uploadFile( id, file )
            .then( () => {
                dispatch( uploadPrFileSuccess() );
            } )
            .catch( error => {
                error.name = "Ошибка загрузки на сервер файла пресс-релиза";
                dispatch( uploadPrFileError( error ) );
            } );
    }
}
//#endregion
