import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSummaries } from "../../actions/summariesActions";

import SummaryGrid from "./SummaryGrid";
import Pager from "../Pager";
import MapComponent from "../Maps/MapComponent";
import Spinner from "../Spinner";
import { Alert, Button } from "react-bootstrap";

class GridWithMap extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            pageNumber: 1,
            itemsPerPage: 10,
            selectedSummaryID: -1,
        };
    }

    handlePageChange = page => {
        this.setState( { pageNumber: page } );
    };

    loadDetails() {
        this.setState( { selectedSummaryID: -1 } );

        this.props.getSummaries_(
            this.props.areaID,
            this.props.search,
            this.state.pageNumber,
            this.state.itemsPerPage
        );
    }

    componentDidMount() {
        this.loadDetails();
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( this.props.isFetching ) return;

        if( prevProps !== this.props ) {
            //  pageNumber храним в локальном state, но он может из fetch приходить пересчитанный (если запросили бОльшую страницу, чем доступно)
            const { pageNumber } = this.props;
            this.setState( { pageNumber } );
        }

        if(
            prevProps.areaID !== this.props.areaID ||
            prevProps.search !== this.props.search ||
            prevState.pageNumber !== this.state.pageNumber
        )
            this.loadDetails();
    }

    onSummaryClick = summaryID => {
        this.setState( { selectedSummaryID: summaryID } );
    };

    render() {
        const { isFetching, error, totalCount, summaries } = this.props;

        if( isFetching )
            return (
                <td>
                    <Spinner />
                </td>
            );

        if( error )
            return (
                <td>
                    <Alert variant="danger">
                        Ошибка загрузки событий: {error.message}.
                    </Alert>
                </td>
            );

        return (
            <React.Fragment>
                <td colSpan="2" style={{ verticalAlign: "top" }}>
                    {this.state.errorDeleting && (
                        <Alert variant="danger">
                            Ошибка удаления события:{" "}
                            {this.state.errorDeleting.message}.
                            &nbsp;&nbsp;&nbsp;
                            <Button onClick={this.handleDismiss}>
                                Закрыть
                            </Button>
                        </Alert>
                    )}
                    <SummaryGrid
                        summaries={summaries}
                        onSummaryClick={this.onSummaryClick}
                    />
                    <div className="text-left">
                        <Pager
                            recCount={totalCount}
                            itemsPerPage={this.state.itemsPerPage}
                            pageNumber={this.state.pageNumber}
                            onPageChange={this.handlePageChange}
                            margin={2}
                        />
                    </div>
                </td>
                <td>&nbsp;</td>
                <td>
                    <MapComponent summaryID={this.state.selectedSummaryID} />
                </td>
            </React.Fragment>
        );
    }
}

GridWithMap.propTypes = {
    summaries: PropTypes.arrayOf(
        PropTypes.shape( {
            date_created: PropTypes.string.isRequired,
            description: PropTypes.string,
            id: PropTypes.number.isRequired,
            area_name: PropTypes.string.isRequired,
            flag: PropTypes.bool,
            objects: PropTypes.string,
            place_name: PropTypes.string,
            pointPlace: PropTypes.string,
            external_link: PropTypes.string,
        } ).isRequired
    ),
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( {
        type: PropTypes.string,
        message: PropTypes.string,
    } ),
    getSummaries_: PropTypes.func.isRequired,
};

function mapStateToProps( state ) {
    const { summariesReducer } = state;

    const {
        isFetching,
        error,
        totalCount,
        pageNumber,
        summaries,
    } = summariesReducer || {
        isFetching: true,
        error: null,
        totalCount: 0,
        pageNumber: 1,
        summaries: [],
    };

    return {
        isFetching,
        error,
        totalCount,
        pageNumber,
        summaries,
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getSummaries_: ( areaID, search, pageNumber, itemsPerPage ) => {
            dispatch( getSummaries( areaID, search, pageNumber, itemsPerPage ) );
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( GridWithMap );
