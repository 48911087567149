import AuthService from "../components/Auth/AuthService";
const Auth = new AuthService();

export class UserIntentionDataAccess {
    fetch = () => {
        return Auth.get( "/api/UserIntention", {} );
    }

    selectUserIntention = ( id, checked ) => {
        return Auth.post( "/api/UserIntention/Select", { ID: id, IsSelected: checked } );
    }

    getQuest = ( id ) => {
        return Auth.get( "/api/UserIntention/" + id, {} );
    }

    uploadQuest( id, file ) {
        let formData = new FormData();
        formData.append( "file", file );

        return Auth.post( "/api/UserIntention/upload/" + id + "/", { headers: {}, body: formData } );
    }
}    
