import React, { Component } from "react";
import PageHeader from "../PageHeader";
import GridWithPager from "./GridWithPager";

class PressReleasesPage extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHeader name="Пресс-релизы" />
                <GridWithPager />
            </React.Fragment>
        );
    }
}

export default PressReleasesPage;

/*
незалогиненный + User: просмотр таблицы одобренных

Editor: кнопка "добавить", нередактируемый чекбокс "одобрено", у своих неодобренных - кнопки "редактировать" и "удалить"

Admin: как редактор для всех плюс возможность одобрения
*/