import { AboutDataAccess } from "../DataAccess/AboutDataAccess";
const DataAccess = new AboutDataAccess();

export const GET_ABOUT_START = "GET_ABOUT_START";
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_ERROR = "GET_ABOUT_ERROR";

function getAboutStart() {
    return {
        type: GET_ABOUT_START
    }
}

function getAboutSuccess( html ) {
    return {
        type: GET_ABOUT_SUCCESS,
        html: html
    }
}

function getAboutError( error ) {
    return {
        type: GET_ABOUT_ERROR,
        error: error
    }
}

export function getAbout() {
    return dispatch => {
        dispatch( getAboutStart() );

        return DataAccess.fetch()
            .then( response => response.text() )
            .then( text => dispatch( getAboutSuccess( text ) ) )
            .catch( error => {
                error.name = "Ошибка загрузки информации о сайте";
                dispatch( getAboutError( error ) );
            } );
    }
}
