import {
    FETCH_MARKERS_START,
    FETCH_MARKERS_SUCCESS,
    FETCH_MARKERS_ERROR,
    RESET_MARKERS,
} from "../actions/markersActions";

export default function markersReducer(
    state = {
        isFetching: false,
        error: null,
        eventMarker: null,
        newsMarkers: [],
        advMarkers: [],
    },
    action
) {
    switch( action.type ) {
        case FETCH_MARKERS_START:
            return Object.assign( {}, state, {
                isFetching: true,
            } );
        case FETCH_MARKERS_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                eventMarker: action.eventMarker,
                newsMarkers: action.newsMarkers,
                advMarkers: action.advMarkers,
            } );
        case FETCH_MARKERS_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error,
            } );
        case RESET_MARKERS:
            return Object.assign( {}, state, {
                isFetching: false,
                error: null,
                eventMarker: null,
                newsMarkers: [],
                advMarkers: [],
            } );
        default:
            return state;
    }
}
