import {
    GET_UPDATES_START,
    GET_UPDATES_SUCCESS,
    GET_UPDATES_ERROR,
    DOWNLOAD_UPDATE_START,
    DOWNLOAD_UPDATE_SUCCESS,
    DOWNLOAD_UPDATE_ERROR,
} from "../actions/updatesActions";

export default function updatesReducer(
    state = {
        isFetching: false,
        error: null,
        updates: [],
    }, action ) {
    switch( action.type ) {
        case GET_UPDATES_START:
        case DOWNLOAD_UPDATE_START:
            return Object.assign( {}, state, {
                isFetching: true,
                error: null,
            } )
        case GET_UPDATES_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false,
                updates: action.updates
            } )
        case DOWNLOAD_UPDATE_SUCCESS:
            return Object.assign( {}, state, {
                isFetching: false
            } )
        case GET_UPDATES_ERROR:
        case DOWNLOAD_UPDATE_ERROR:
            return Object.assign( {}, state, {
                isFetching: false,
                error: action.error
            } )
        default:
            return state;
    }
}
