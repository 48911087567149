import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getManageUserIntentions,
    /*removeEvent,
    updateEvent,
    addEvent,*/
    getUserQuest,
    resetManageUserIntentionsError,
    setUiConfirmed,
} from '../../actions/manageUserIntentionActions';

import Grid from './Grid';
import Pager from '../Pager';
import Spinner from '../Spinner';
import AuthService from '../Auth/AuthService';
import Loader from 'react-loader-advanced';
import DangerAlert from "../DangerAlert";

class GridWithPager extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            pageNumber: 1,
            itemsPerPage: 10
        };

        this.Auth = new AuthService();
        this.spinner = <Spinner />;
    }

    handlePageChange = ( page ) => {
        this.setState( { pageNumber: page } );
    }

    loadDetails() {
        this.props.getManageUserIntentions_( this.state.pageNumber, this.state.itemsPerPage );
    }

    componentDidMount() {
        this.loadDetails();
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( this.props.isFetching )
            return;

        if( prevProps !== this.props ) {
            //  pageNumber храним в локальном state, но он может из fetch приходить пересчитанный (если запросили бОльшую страницу, чем доступно)
            const { pageNumber } = this.props;
            this.setState( { pageNumber } );
        }

        if( prevState.pageNumber !== this.state.pageNumber )
            this.loadDetails();
    }

    getUserQuest = ( user_id, intention_id, email, intention_name ) => {
        this.props.getUserQuest_( user_id, intention_id )
            .then( result => {
                var fileDownload = require( "js-file-download" );
                fileDownload( result.text, email + "--" + intention_name + ".csv" );
            } );
    }

    setConfirmed = ( user_id, intention_id, checked ) => {
        this.props.setConfirmed_( user_id, intention_id, checked );
    }

    render() {
        const { isFetching, error, intentions, totalCount } = this.props;

        return (
            <React.Fragment>
                <DangerAlert
                    error={error}
                    onClose={() => this.props.resetError_()}
                />
                <Loader show={isFetching} message={this.spinner}>
                    <Grid
                        intentions={intentions}
                        getUserQuest={this.getUserQuest}
                        setConfirmed={this.setConfirmed}
                    />
                </Loader>
                <div className="text-left">
                    <Pager
                        recCount={totalCount}
                        itemsPerPage={this.state.itemsPerPage}
                        pageNumber={this.state.pageNumber}
                        onPageChange={this.handlePageChange}
                        margin={2}
                    />
                </div>
            </React.Fragment> );
    }
}

GridWithPager.propTypes = {
    intentions: PropTypes.arrayOf( PropTypes.shape( {
        registration_date: PropTypes.string.isRequired,
        user_id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        intention_id: PropTypes.number.isRequired,
        intention_name: PropTypes.string.isRequired,
        is_confirmed: PropTypes.bool.isRequired,
    } ).isRequired ).isRequired,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.shape( { type: PropTypes.string, name: PropTypes.string, message: PropTypes.string } ),
    getManageUserIntentions_: PropTypes.func.isRequired,
    resetManageUserIntentionsError_: PropTypes.func.isRequired,
    getUserQuest_: PropTypes.func.isRequired,
}

function mapStateToProps( state ) {
    const { manageUserIntentionReducer } = state;

    const {
        totalCount, pageNumber, intentions,
        isFetching, error } = manageUserIntentionReducer || {
            totalCount: 0, pageNumber: 1, intentions: [],
            isFetching: true, error: null
        };

    return {
        totalCount, pageNumber, intentions,
        isFetching, error
    };
}

function mapDispatchToProps( dispatch ) {
    return {
        getManageUserIntentions_: ( pageNumber, itemsPerPage ) => {
            dispatch( getManageUserIntentions( pageNumber, itemsPerPage ) );
        },
        resetManageUserIntentionsError_: () => {
            return dispatch( resetManageUserIntentionsError() );
        },
        getUserQuest_: ( user_id, intention_id ) => {
            return dispatch( getUserQuest( user_id, intention_id ) );
        },
        setConfirmed_: ( user_id, intention_id, checked ) => {
            return dispatch( setUiConfirmed( user_id, intention_id, checked ) );
        },
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( GridWithPager );