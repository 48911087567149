import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Circle } from "react-google-maps"
import InfoWindowContent from "./InfoWindowContent";

function createPoint( obj ) {
    return { lat: obj.latitude, lng: obj.longitude };
}

const MapWithAMarkers = compose(
    withProps( {
        googleMapURL: "https://maps.googleapis.com/maps/api/js?language=ru&key=AIzaSyCSCiK05luykSswzJLLs76gjckcNOlEQFU",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `483px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    } ),
    withScriptjs,
    withGoogleMap
)( props =>
    <GoogleMap ref={props.onMapMounted} defaultZoom={5}>
        <Marker
            key={props.eventMarker.id}
            position={createPoint( props.eventMarker )}
            title={props.eventMarker.place_name}
            label={props.eventMarker.objects}
            icon='https://www.google.com/intl/en_us/mapfiles/ms/micons/red-dot.png'
            onClick={props.onInfoWindowClick( props.eventMarker.id )}
        >
            {props.infoWindowsVisible && props.infoWindowsVisible[ props.eventMarker.id ] && <InfoWindow onCloseClick={props.onInfoWindowClick( props.eventMarker.id )}>
                <InfoWindowContent text={props.eventMarker.description} />
            </InfoWindow>}
        </Marker>

        <Circle
            center={createPoint( props.eventMarker )}
            radius={1000}
            strokeColor='#FF0000'
            strokeOpacity='0.8'
            strokeWeight='2'
            fillColor='#FF0000'
            fillOpacity='0.35' />

        {props.newsMarkers && props.newsMarkers.map( marker => (
            <Marker
                key={marker.id}
                position={createPoint( marker )}
                title={marker.place_name}
                label={marker.objects}
                icon='https://www.google.com/intl/en_us/mapfiles/ms/micons/red.png'
                onClick={props.onInfoWindowClick( marker.id )}
            >
                {props.infoWindowsVisible && props.infoWindowsVisible[ marker.id ] && <InfoWindow onCloseClick={props.onInfoWindowClick( marker.id )}>
                    <InfoWindowContent text={marker.description} />
                </InfoWindow>}
            </Marker>
        ) )}

        {props.advMarkers && props.advMarkers.map( marker => (
            <Marker
                key={marker.id}
                position={createPoint( marker )}
                title={marker.place_name}
                label={marker.objects}
                icon='https://www.google.com/intl/en_us/mapfiles/ms/micons/green.png'
                onClick={props.onInfoWindowClick( marker.id )}
            >
                {props.infoWindowsVisible && props.infoWindowsVisible[ marker.id ] && <InfoWindow onCloseClick={props.onInfoWindowClick( marker.id )}>
                    <InfoWindowContent text={marker.description} />
                </InfoWindow>}
            </Marker>
        ) )}
    </GoogleMap>
);

export default MapWithAMarkers;