import React from 'react';

class InfoWindowContent extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {};
    }

    splitString( str ) {
        var newLines = [];

        var maxLength = 30;

        var currentLine = "";

        var lines = str.split( " " );

        for( var i = 0; i < lines.length; i++ ) {
            var line = lines[ i ];

            if( currentLine === "" && line.length > maxLength ) {
                newLines.push( line );
                continue;
            }

            if( currentLine.length + 1 + line.length > maxLength ) {
                newLines.push( currentLine );
                currentLine = line;
                continue;
            }

            if( currentLine !== "" )
                currentLine += " ";

            currentLine += line;
        }

        if( currentLine !== "" ) {
            newLines.push( currentLine );
        }

        return newLines;
    }

    componentDidMount() {
        this.setState( { lines: this.splitString( this.props.text ) } )
    }

    render() {
        if( !this.state.lines ) return "";

        return (
            <div>
                {this.state.lines.map( ( line, key ) => {
                    return <div key={key}>{line}</div>;
                } )}
            </div> );
    }
}

export default InfoWindowContent;
