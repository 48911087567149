import AuthService from '../components/Auth/AuthService';
const Auth = new AuthService();

export class PressReleasesDataAccess {
    fetch = ( pageNumber, itemsPerPage ) => {
        return Auth.get( "/api/PressRelease", { pageNumber: pageNumber, pageSize: itemsPerPage } );
    }

    update = ( pr ) => {
        return Auth.put( "/api/PressRelease/" + pr.id, { text: pr.text } );
    }

    add = ( pr ) => {
        return Auth.put( "/api/PressRelease/", { text: pr.text } );
    }

    getFile = ( filename ) => {
        return Auth.get( "/api/PressRelease/GetFile/" + filename, {} );
    }

    remove = ( id ) => {
        return Auth.delete( "/api/PressRelease/" + id, {} );
    }

    setConfirmed = ( id, checked ) => {
        return Auth.post( "/api/PressRelease/SetConfirmed", { ID: id, IsConfirmed: checked } );
    }

    uploadFile( id, file ) {
        let formData = new FormData();
        formData.append( "file", file );

        return Auth.post( "/api/PressRelease/upload/" + id + "/", { headers: {}, body: formData } );
    }
}    
